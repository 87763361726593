import React from 'react'
import Achievement1 from '../../image/achievements/achievement-1.png'
import Achievement2 from '../../image/achievements/achievement-2.png'
import Achievement3 from '../../image/achievements/achievement-3.png'
import Achievement4 from '../../image/achievements/achievement-4.png'
import Achievement5 from '../../image/achievements/achievement-5.png'
import Achievement6 from '../../image/achievements/achievement-6.png'
import Achievement7 from '../../image/achievements/achievement-7.png'
import Achievement8 from '../../image/achievements/achievement-8.png'
import Achievement9 from '../../image/achievements/achievement-9.png'
import Achievement10 from '../../image/achievements/achievement-10.png'
import Achievement11 from '../../image/achievements/achievement-11.png'
import Achievement12 from '../../image/achievements/achievement-12.png'

import Achievement13 from '../../image/achievements/achievement-13.png'
import Achievement14 from '../../image/achievements/achievement-14.png'
import Achievement15 from '../../image/achievements/achievement-15.png'
import Achievement16 from '../../image/achievements/achievement-16.png'
import Achievement17 from '../../image/achievements/achievement-17.png'
import Achievement18 from '../../image/achievements/achievement-18.png'
import Achievement19 from '../../image/achievements/achievement-19.png'
import Achievement20 from '../../image/achievements/achievement-20.png'
import Achievement21 from '../../image/achievements/achievement-21.png'
import Achievement22 from '../../image/achievements/achievement-22.png'
import Achievement23 from '../../image/achievements/achievement-23.png'
import Achievement24 from '../../image/achievements/achievement-24.png'
import Achievement25 from '../../image/achievements/achievement-25.png'
import Achievement26 from '../../image/achievements/achievement-26.png'
// import Achievement27 from '../../image/achievements/achievement-27.png'
import Achievement28 from '../../image/achievements/achievement-28.png'
import Achievement29 from '../../image/achievements/achievement-29.png'
import Achievement30 from '../../image/achievements/achievement-30.png'

import Achievement31 from '../../image/achievements/achievement-31.png'
import './index.less'

export default function Achievement() {

    return (
        <>
            <div className='achievement-banner'></div>
            <div className='achievement-wrapper'>
                <div className='achieve-service m-t40 m-b20'>
                    <img src={Achievement1} />
                </div>

                <div className='achievement-wrapper-service'>
                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement2} />
                       </div>
                       <p><b>“Service to Man is Service to God”</b></p>
                       <p className='info'>Manava Seva Madhava Seva. Jana Seva Janardhana Seva. Nara Seva Narayana Seva.</p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement3} />
                       </div>
                       <p><b>1987: Samatvam</b></p>
                       <p className='info'>With this humble objective, <b>“Samatvam”</b> [referring to <span className='orange-theme'>Yoga or Equanimity</span>], began its public service 
                           activities in 1987 in New Delhi, India, with the inspiration of Padma Sri Professor Man Mohan Singh Ahuja, 
                           the Scientist - Sage and Father of Indian Endocrinology Diabetology.  The service areas of Samatvam include 
                           health care, education, research and social welfare.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement4} />
                       </div>
                       <p><b>1993: Samatvam</b></p>
                       <p className='info'>Beginning 1993, enriched by a small group of Doctors – Counsellors – Social Workers - and 
                        Volunteers, Samatvam Trust, crystallized as a grass roots health care institution based in Bangalore. Endocrinology 
                        - Diabetes and related disorders and Community Medicine, were the primary focus.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement5} />
                       </div>
                       <p><b>2003: Jnana Sanjeevini</b></p>
                       <p className='info'>In 2003, led by doyens of our nation like, Dr GVK Rao, Dr H Narasimhiah and 
                        Sri MA Srinivas, <b>Samatvam Trust</b> established <b>Jnana Sanjeevini</b> [referring to Knowledge for 
                        Eternal Health], as an “all under one roof - multispecialty” Diabetes Endocrinology Day Care 
                        Center, in its own premises in JP Nagar, Bangalore.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement6} />
                       </div>
                       <p><b>2014: Jnana Sanjeevini</b></p>
                       <p className='info'>2014: Jnana Sanjeevini:  Since 2014, with the continued inspiration and support of revered Honorable Sri MN Venkatachaliah, Sri A Ramaswamy, Sri GC Surana family and other “Margadarshis”, patrons and benefactors, Jnana Sanjeevini is trying to extend basic hospital in-patient services to the needy, at is premises.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement7} />
                       </div>
                       <p><b>Samatvam - Jnana Sanjeevini – Services at affiliated hospitals</b></p>
                       <p className='info'> Since its inception, besides our day care centers, the Samatvam Team of Specialist Endocrinologists – Diabetologists and Diabetes Endocrinology Counsellors, also provide comprehensive out- patient, in-patient and 24 hour emergency services via several affiliated secondary and tertiary care hospitals spread across the city of Bangalore, catering to every socioeconomic segment of our society.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement8} />
                       </div>
                       <p><b>2012: Samatvam Jnana Sanjeevini Celebrating  25 Years of public service</b></p>
                       <p className='info'>Samatvam completed 25 years of public service, touching the health and lives of more than 100000 fellow citizens with diabetes - adults and children, poor and rich, from Karnataka and neighboring states and even other parts of India [and few from other countries from USA to Australia]. <span className='orange-theme'>“No one will leave Samatvam - Jnana Sanjeevini uncared and unprovided, irrespective of their socioeconomic status.”</span> This was our opening credo.  We continue to preserve and persevere this till today.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement9} />
                       </div>
                       <p><b>Service with Devotion</b></p>
                       <p className='info'> <span className='orange-theme'>“Service with devotion”</span> is our motto.  Each patient deserves the best of our knowledge, skills and most importantly humanity.  Health care of highest possible quality to the highest possible quantity is the universal need. 
                        <br></br>
                        <span className='orange-theme'>“Let every action of mine be something beautiful for God.” -  Mother Teresa</span>
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement10} />
                       </div>
                       <p><b>“People and Team – “Jana Shakthi”</b></p>
                       <p className='info'>People and team are our core [and perhaps only] strength. Patient is always the captain of our Team. Together everyone achieves more.  Commitment to reach out to the poor and needy is permanent.
                       <br></br>
                       <span className='orange-theme'>“ Be the change you want to be.” -  Mahatma Gandhi
                       <br></br>
                            “Let your life be your message.” -  Mahatma Gandhi</span>
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement11} />
                       </div>
                       <p><b>Power of Prevention</b></p>
                       <p className='info'><span className='orange-theme'>“Power of prevention”</span> is our slogan.  Diabetes and its complications are preventable: Heart attacks, strokes, blindness, kidney failure, gangrene / leg amputations  are preventable.  Angioplasty and bypass surgeries are preventable, to a major extent.  The answer is in your hands,  …..   ….. Samatvam - Jnana Sanjeevini is there to help you.
                       <br></br>
                       <span className='orange-theme'>“Arise, awake and stop not till your goal is reached.” -  Swami Vivekananda
                       <br></br>
                        “Be and Make.”  -  Swami Vivekananda.</span>
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement12} />
                       </div>
                       <p><b>Samatvam – Jnana Sanjeevini – Pioneering service to people and nation</b></p>
                       <p className='info'>During this long journey, at several steps, we have pioneered novel Diabetes patient and public health education programs, innovated Outreach rural and urban Diabetes camps and implemented Model self-help community care services, for our people and nation.  All with social relevance, for human and societal good.
                        </p>
                    </div>
                </div>
                
                
                <p className='sub-title'>Through these activities, Samatvam [despite being a small grass roots organization] has earned to its credit several <span className='orange-theme'>FIRSTS or EARLIESTS or LARGESTS,</span> in the health care arena of our country; these include</p>
                <div  className='achievement-wrapper-service'>    
                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement13} />
                       </div>
                       <p><b>Structured Hospital, Clinic and Community Based Diabetes Patient Education Programs</b></p>
                       <p className='info'>
                       Implementation of structured hospital, clinic and community based diabetes patient education programs
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement14} />
                       </div>
                       <p><b>Diabetes Educators, Counselors, Specialist Nurses, Physician Assistants, Social Workers and Podiatrists</b></p>
                       <p className='info'>
                            Training of Diabetes Educators, Counselors, Specialist Nurses, Physician Assistants, Social Workers and Podiatrists
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement15} />
                       </div>
                       <p><b>Childhood Diabetes Clinics, Residential Diabetes Camps and Patient Parent Support Groups</b></p>
                       <p className='info'>
                            Organization of Childhood Diabetes Clinics, Residential Diabetes Camps and Patient Parent Support Groups
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement16} />
                       </div>
                       <p><b>24 Hour FREE Diabetes Helplines</b></p>
                       <p className='info'>
                       <span className='orange-theme'>World’s longest and busiest 24 hour FREE Diabetes Helpline[s];</span> which has [have] even catered to patients and families from far off countries [across the globe], for both general and emergency medical support
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement17} />
                       </div>
                       <p><b>Mass Print and Electronic Media</b></p>
                       <p className='info'>
                       Successfully utilization of mass print and electronic media, to disseminate our message of Power of Prevention
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement18} />
                       </div>
                       <p><b>Insulin Pump and Continuous Glucose Monitoring Program</b></p>
                       <p className='info'>
                       Implementation, in 2002, of the first certified Insulin Pump and Continuous Glucose Monitoring Program in India
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement19} />
                       </div>
                       <p><b>Peers for Health or Madhura Sanjeevini -  Community Health Workers  and Health Ambassadors</b></p>
                       <p className='info'>
                       <span className='orange-theme'>“Peers for Health” or “Madhura Sanjeevini”</span>, where in diabetes patients or their family members are themselves trained, equipped, deployed and empowered as Community Health Workers [or so called Bare Foot Doctors], to serve as Health Ambassadors for their own community – ie villages and urban slums. Samatvam <span className='orange-theme'>“Helps them help themselves”</span>.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement20} />
                       </div>
                       <p><b>Project DISHA</b></p>
                       <p className='info'>
                       The highlights of Project DISHA which supports Type I (insulin dependent) diabetes children and youth, include: FREE Childhood Diabetes Clinic, FREE Insulin lifeline and Insulin for every child, <span className='orange-theme'>“Insulin PLUS Education”</span>: where in possible financial support is provided for their formal school and college education, towards making these youngsters proud and productive citizens of the nation
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement21} />
                       </div>
                       <p><b>Project DOSTI</b></p>
                       <p className='info'>
                       Project DOSTI supports Type II (non – insulin dependent) diabetes adults through FREE Adult Diabetes Clinic, FREE medicines, ie <span className='orange-theme'>“Health for Helpless”</span> program, and other social welfare services
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement22} />
                       </div>
                       <p><b>Project DEEPA</b></p>
                       <p className='info'>
                       Project DEEPA is directed towards Diabetes physicians, counselors and other health professionals.  This fosters the much needed Diabetes Health Care Manpower Development and Capacity Building, through local, national and international Scholarships - Observerships - Internships and Fellowships, and Continuing Medical Education and training.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement23} />
                       </div>
                       <p><b>Igniting the Minds – Samatvam Alumni</b></p>
                       <p className='info'>
                       Samatvam Alumni: ie. Medical and other students, physician residents, fellows and consultants, nurses, counselors, social workers and scientists, all inspired, trained and nurtured by Samatvam – Jnana Sanjeevini, are currently serving people and communities across the world, in various specialties and capacities.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement24} />
                       </div>
                       <p><b>Torch of Life – Diabetes health care manpower capacity building</b></p>
                       <p className='info'>
                       In collaboration with <span className='orange-theme'><b>Public Health Foundation of India</b></span>, Samatvam has trained the record highest number [for the country] of primary care and specialist doctors, to deliver higher standards of diabetes care for the people and families served by them.  The philosophy of Service with devotion, scientific spirit and passionate and compassionate medical care have been intensively infused.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement25} />
                       </div>
                       <p><b>Science and Research</b></p>
                       <p className='info'>
                       In the area of scientific and social research, Samatvam has focused on:  Diabetes care in resource limited settings, Patient education and empowerment, Psychosocial support and Spiritual Medicine.  With increasing globalization in science, Samatvam has been in the international forefront of several drug and device clinical trials, with an emphasis on patient safety, good science, future promise and most importantly applicability and relevance to the Indian society.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement26} />
                       </div>
                       <p><b>Guinness World Record – Largest diabetes awareness lesson - Multiple venues: Samatvam – Micro Labs - Rotary International District 3190, Karnataka, India</b></p>
                       <p className='info'>
                       “The largest diabetes awareness lesson (Multiple venues) is 8,814 participants achieved at events organized by Rotary International District 3190 and Samatvam (both India) in Karnataka, India, on 23 February 2017. For the purpose of this record a diabetes awareness lesson is defined as a lesson based on how diabetes can affect health, how it can be avoided, and how it can be handled in day to day life.”
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement28} />
                       </div>
                       <p><b>Samatvam – Jnana Sanjeevini Philosophy and Mission</b></p>
                       <p className='info'>
                       Hospitals are temples, churches and mosques;   Revered patients are “Living Gods”; Doctors and other health care workers are priests and devotees, who have been gifted with the divine opportunity to worship and to be blessed.<span className='orange-theme'> “Science without religion is lame; Religion without science is blind.”</span> -   Dr Albert Einstein
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement29} />
                       </div>
                       <p><b>Science – Spirituality Synthesis</b></p>
                       <p className='info'>
                            In this path, Samatvam is a continuing vision, an ever challenging mission, a growing health care team and a committed professional family.  In the context of health care: <span className='orange-theme'>“Bridging the Best of East and West”</span>, is our goal; “<span className='orange-theme'>Integration of Science and Spirituality”</span> is our foundation.
                        </p>
                    </div>

                    <div className='single-achievement'>
                       <div className='img-container'>
                            <img src={Achievement30} />
                       </div>
                       <p><b>Samatvam – Jnana Sanjeevini:  Future?</b></p>
                       <p className='info'>
                       The woods are lovely, dark, and deep,
                        <br></br>But I have promises to keep, 
                        <br></br>And miles to go before I sleep, 
                        <br></br>And miles to go before I sleep. 
                        <br></br>[“Stopping by Woods on a Snowy Evening” - Robert Frost]
                        <br></br><span className='orange-theme'>“Science can only prolong life; Religion deepens it.”  - Dr Yellapragada Subba Row.</span>
                        </p>
                    </div>
                </div>
                <div className='achieve-service'>
                    <img src={Achievement31} className='m-b80 m-t20' />
                </div>
            </div>
        </>
    )
}

