import React from 'react'
import {
	Link
  } from "react-router-dom";
import './index.less'

export default function Doctors() {

    return (
        <>
        <div className='doctor-bg'></div>
        <div className='full-doctors-wrapper d-f'>
            <div>
            <p className='main-title'>Eminence and Excellence</p>
            <p className='sub-title'>Doctors: Endocrinology Diabetes</p>
            <ul>
                <li><Link to="/dr-s-sri-kanta">Dr S Srikanta, <span>MD (AIIMS), (PhD), FEDM, FACE [USA], Hon FRCP [London]</span></Link></li>
                <li><Link to="#">Dr A Sharda, <span>MD, DNB, FEDM, FACE [USA], FRCP</span></Link></li>
                <li><Link to="#">Dr Tejaswini Patil, <span>MD, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Bhanudev M Bhatt, <span>MD, FRCP</span></Link></li>
                <li><Link to="#">Dr T Kamala, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Pushpa Ravikumar, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Lakshmi V Reddy, <span>MBBS, FEDM, FACE [USA], (Fellow ISPAD)</span></Link></li>
                <li><Link to="#">Dr KM Chandrika, <span>MBBS, FEDM, FACE[ USA], (Fellow ISPAD)</span></Link></li>
                <li><Link to="#">Dr K Sumathi, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr S Chandraprabha, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Nidhi Garg, <span>MD, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Vasanti Nath, <span>MBBS, DGO, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr BV Reshma, <span>MBBS, FEDM, FACE [USA], Fellow ISPAD</span></Link></li>
                <li><Link to="#">Dr Babitha Devi, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Jayalakshmi Thimmaiah, <span>MBBS, CDE, FEDM</span></Link></li>
                <li><Link to="#">Dr Vasudha Srikanth, <span>MBBS, FEDM, PGDC [Cardiology]</span></Link></li>
                <li><Link to="#">Dr A Sumithra, <span>MBBS, MD</span></Link></li>
                <li><Link to="#">Dr MD Chitra, <span>MBBS, FEDM, CDE</span></Link></li>
                <li><Link to="#">Dr Kavitha Muniraj, <span>MBBS, FEDM, FACE [USA]</span></Link></li>
                <li><Link to="#">Dr Apoorva Govind, <span>MMBBS, FEDM, CDE, Fellow ISPAD</span></Link></li>
                <li><Link to="#">Dr V Vasanthakumari, <span>MBBS, CDE</span></Link></li>
                <li><Link to="#">Dr KV Rajiv, <span>MBBS, FEDM, CDE, Fellow ISPAD</span></Link></li>
                <li><Link to="#">Dr CS Muralidhara Krishna, <span>MD, FEDM, FACE [USA], Fellow ISPAD</span></Link></li>
                <li><Link to="#">Dr VGB Bhaskar Rao, <span>MBBS, DA, CDE</span></Link></li>
            </ul>
            </div>

            <div>
                <p className='main-title'>Counsellors: Diabetes Education</p>
                <p className='sub-title'>Physician Assistants Endocrinology Diabetes</p>
                <ul>
                    <li><Link to="#">Ms Uma Dayashankar, <span>BSC, CDE, PA, (Fellow ISPAD)</span></Link></li>
                    <li><Link to="#">Ms Usha Rangaraj, <span>BA, DNHE, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms N S Vijayalakshmi, <span>BSC, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms H K Vasanthalakshmi, <span>BSC, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms Prabha Jagadish, <span>BA, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms Nandini Jayaram, <span>BSC, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms Rajeshwari Ashok, <span>BA, DMLT, CDE, PA (Award ICE)</span></Link></li>
                    <li><Link to="#">Ms Geetha Rao, <span>GNM , RNM, CDE, PA, (Award ISPAD)</span></Link></li>
                    <li><Link to="#">Ms Nagamani Srinivas, <span>BNYS, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms Madhumati S V, <span>MSC, CDE, PA (PhD), Fellow ISPAD, Award ICE</span></Link></li>
                    <li><Link to="#">Ms Bina N Naik, <span>MSC, CDE, PA (Fellow ISPAD)</span></Link></li>
                    <li><Link to="#">Ms KL Chethana, <span>MSC, CDE, PA, Fellow ISPAD</span></Link></li>
                    <li><Link to="#">Ms Rohini Manjunath, <span>BSC, CDE, PA, Fellow ISPAD</span></Link></li>
                    <li><Link to="#">Mr D Mallikarjun Rao, <span>PG, DFD, Podiatry</span></Link></li>
                    <li><Link to="#">Mr Owais S M H, <span>BSC, CDE, PA, (Award ICE)</span></Link></li>
                    <li><Link to="#">Ms LB Akshaya Hegde, <span>BA, DNHE, CDE, PA (Award ICE)</span></Link></li>
                    <li><Link to="#">Ms B Marimuthu, <span>DHA, CDE, PA, GNM (Award ICE)</span></Link></li>
                    <li><Link to="#">Ms Mary Priya, <span>AWM, CDE, PA (Award IDF)</span></Link></li>
                    <li><Link to="#">Ms Varalakshmi Shivraj, <span>AWM, CDE, PA, Award IDF</span></Link></li>
                    <li><Link to="#">Ms M Suma, <span>AWM, CDE, PA</span></Link></li>
                    <li><Link to="#">Ms Padmaja Srinivas, <span>MA, LLB, CDE, PA</span></Link></li>
                    {/* <li><Link to="#">, <span></span></Link></li> */}
                </ul>
            </div>

        </div>
        </>
    )}
    