import {Route, BrowserRouter, HashRouter as Router, Switch} from 'react-router-dom';
import React, {Suspense} from 'react';
import AppLayout from './components/AppLayout'
import Homepage from './views/Homepage'
import AboutUs from './views/AboutUs'
import Diabetes from './views/Diabetes'
import Endocrinology from './views/Endocrinology'
import Specialities from './views/Specialities'
import Doctors from './views/Doctors'
import Education from './views/Education'
import Facilities from './views/Facilities'
import Research from './views/Research'
import ContactUs from './views/ContactUs'
import InternationalPatients from './views/InternationalPatients'
import HealthProfessional from './views/HealthProfessional'
import Charity from './views/Charity'
import Testimonial from './views/Testimonials'
import Achievements from './views/Achievements'
import PhotosAndGallery from './views/PhotosAndGallery'
import NewsLetter from './views/NewsLetter'
import NewsAndEvents from './views/NewsAndEvents'
import SSriKanta  from './views/AllDoctors/SSriKanta'
import ScrollToTop from './hooks/ScrollToTop';

export default function AppRouter() {
   return (
    <BrowserRouter>

    <Router>
    <ScrollToTop>
        <AppLayout>
            {/* <Suspense fallback={<Loader/>}> */}
        <Suspense>
        <Switch>
            <Route path='/' exact component={() => <Homepage />} />
            <Route path='/about-us' exact component={() => <AboutUs />} />
            <Route path='/diabetes' exact component={() => <Diabetes />} />
            <Route path='/endocrinology' exact component={() => <Endocrinology />} />
            <Route path='/specialties' exact component={() => <Specialities />} />
            <Route path='/doctors' exact component={() => <Doctors />} />
            <Route path='/education' exact component={() => <Education />} />
            <Route path='/facilities' exact component={() => <Facilities />} />
            <Route path='/research' exact component={() => <Research />} />
            <Route path='/contact-us' exact component={() => <ContactUs />} />
            <Route path='/international-patients' exact component={() => <InternationalPatients />} />
            <Route path='/health-professional' exact component={() => <HealthProfessional />} />
            <Route path='/charity' exact component={() => <Charity />} />
            <Route path='/testimonial' exact component={() => <Testimonial />} />
            <Route path='/achievements' exact component={() => <Achievements />} />
            <Route path='/photos-gallery' exact component={() => <PhotosAndGallery />} />
            <Route path='/newsletter' exact component={() => <NewsLetter />} />
            <Route path='/news-events' exact component={() => <NewsAndEvents />} />

            <Route path='/dr-s-sri-kanta' exact component={() => <SSriKanta />} />
        </Switch>
        </Suspense>
        
        </AppLayout>
        </ScrollToTop>
       
    </Router>
    </BrowserRouter>

   )
};
