import React from 'react'
import Clinic from '../../image/icons/clinic.svg'
import Hospital from '../../image/icons/hospital.svg'
import Pin from '../../image/icons/pin.svg'
import Mobile from '../../image/icons/mobile.svg'
import Phone from '../../image/icons/phone.svg'
import Mail from '../../image/icons/mail.svg'
import Doctor from '../../image/icons/doctor.svg'
import ContactUsForm from '../../components/ContactUsForm'
import './index.less'

export default function ContactUs() {

    return (
        <>
        <div className='contact-us-banner'></div>
        <div className='contact-us-wrapper'>
            {/* Centers */}
            <p className='main-title'>Samatvam Endocrinology Diabetes Centers</p>
            <div className='diabetes-center'>
                <div className='diabletes-desc'>
                    {/* <img src={Clinic} alt='location' className='location' /> */}
                    <div className='d-f m-b15'>
                        <img src={Pin} className='img-icon' />
                        <p className='address'>
                            <a href='https://www.google.com/maps/place/12.92607+77.5866683/@12.92607,77.5866683,17z' target='_blank'>313, 33 Cross, 17th Main, Jayanagar 4th T Block, Bangalore 560 041, India</a>
                        </p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Phone} className='img-icon' />
                        <p>4111 3308, 2663 7486</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mobile} className='img-icon' />
                        <p>9448356575</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mail} className='img-icon' />
                        <p>samatvam@gmail.com</p>
                    </div>
                </div>

                <div className='diabletes-desc'>
                    {/* <img src={Clinic} alt='location' className='location' /> */}
                    <div className='d-f m-b15'>
                        <img src={Pin} className='img-icon' />
                        <p className='address'>
                            <a href='https://www.google.com/maps/place/12.9827409+77.6374418/@12.9827409,77.6374418,17z' target='_blank'>640, I Main, I Stage, Indiranagar, Bangalore 560 038, India</a>
                        </p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Phone} className='img-icon' />
                        <p> 91 80 - 25213156, 25257508</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mobile} className='img-icon' />
                        <p>9449994978</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mail} className='img-icon' />
                        <p>sharda.a@gmail.com</p>
                    </div>
                </div>

                <div className='diabletes-desc m-b80'>
                    {/* <img src={Clinic} alt='location' className='location' /> */}
                    <div className='d-f m-b15'>
                        <img src={Pin} className='img-icon' />
                        <p className='address'>
                            <a href='https://www.google.com/maps/place/12.9188736+77.618257/@12.9188736,77.618257,17z' target='_blank'>82, 36th Main 4th A cross BTM 1st Stage, 1st Phase, Dollar's Scheme, Bangalore - 560068, India</a>
                        </p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Phone} className='img-icon' />
                        <p>7022635742</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mobile} className='img-icon' />
                        <p>7022635742</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mail} className='img-icon' />
                        <p> samatvambtm@gmail.com</p>
                    </div>
                </div>
            </div>

            {/* Hospitals */}
            
            <p className='main-title'>Jnana Sanjeevini Medical Center and Diabetes Hospitals</p>
            <div className='diabetes-hospital m-t80'>
                <div className='diabletes-desc'>
                    {/* <img src={Hospital} alt='location' className='location' /> */}
                    <div className='d-f m-b15'>
                        <img src={Pin} className='img-icon' />
                        <p className=''>
                            <a href='https://www.google.com/maps/place/12.915217+77.5841653/@12.915217,77.5841653,17z' target='_blank'>2, 1 A Cross Marenahalli, JP Nagar Phase 2; Bangalore 560078, India</a>
                        </p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Phone} className='img-icon' />
                        <p>91 - 80 - 26493040, 26493060</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mobile} className='img-icon' />
                        <p>9448350728</p>
                    </div>

                    <div className='d-f m-b15'>
                        <img src={Mail} className='img-icon' />
                        <p>jsmcindia@gmail.com</p>
                    </div>
                </div>
                <div className='contact-form'>
                    <ContactUsForm />
                </div>
            </div>

            {/* Specialized Doctors */}
            <div className='specialised-doc'>
                <p className='main-title'>Medical Directors: Senior Consultants Endocrinology Diabetes </p>
                <div className='d-f jc-sb m-t80'>
                    <div className='contact-us-doctors-desc'>
                    {/* <img src={Doctor} width={40}/> */}
                        <div className='contact-us-doctors-info'>
                            <p className='name'>Dr S Srikanta</p>
                            <p className='qualification'>MD (AIIMS), (PhD), FACE (Duke University Medical Center, Joslin Diabetes Center, Harvard Medical School, USA)</p>
                            <p className='m-tb-10'><i>samatvam@gmail.com </i></p>
                        </div>
                    </div>

                    <div className='contact-us-doctors-desc'>
                    {/* <img src={Doctor} width={40}/> */}
                        <div className='contact-us-doctors-info'>
                            <p className='name'>Dr. A Sharda</p>
                            <p className='qualification'>MD, DNB, MRCP, FACE, FRCP (University California, Henry Ford Hospital, USA; McGill University, Canada</p>
                            <p className='m-tb-10'> <i>sharda.a@gmail.com</i></p>
                        </div>
                    </div>

                    <div className='contact-us-doctors-desc'>
                    {/* <img src={Doctor} width={40}/> */}
                        <div className='contact-us-doctors-info'>
                            <p className='name'>Dr. Tejaswini Patil</p>
                            <p className='qualification'>MD, FEDM, FACE (University Liverpool, Birmingham Heartlands Hospital, Royal Albert Infirmary, UK)</p>
                            <p className='m-tb-10'> <i>samatvambtm@gmail.com</i></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}