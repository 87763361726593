import React from 'react';
import {Header, Layout, Menu} from '../Base/Layout.js';
import {
  Link
} from "react-router-dom";
import './index.less'
import useRouter from '../../state/hooks/useRouter';

export default function HeaderLayout() {
    const {match} = useRouter();
    console.log("Homepage -> match", match)
    return (
    
      <Layout>
        <Header className="header">
          <div className="logo" />
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['home']}>
            <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
            <Menu.Item key="about-us"><Link to="/about-us">About Us</Link></Menu.Item>
            <Menu.Item key="diabetes"><Link to="/diabetes">Diabetes</Link></Menu.Item>
            <Menu.Item key="endocrinology"><Link to="/endocrinology">Endocrinology</Link></Menu.Item>
            <Menu.Item key="specialties"><Link to="/specialties">Specialities</Link></Menu.Item>
            <Menu.Item key="doctors"><Link to="/doctors">Doctors</Link></Menu.Item>
            <Menu.Item key="education"><Link to="/education">Education</Link></Menu.Item>
            <Menu.Item key="research"><Link to="/research">Research</Link></Menu.Item>
            <Menu.Item key="facilities"><Link to="/facilities">Facilities</Link></Menu.Item>
            <Menu.Item key="contact-us"><Link to="/contact-us">Contact Us</Link></Menu.Item>
          </Menu>
        </Header>
      </Layout>
    )
}