import React from 'react'
import Mobile from '../../image/icons/mobile.svg'
import Mail from '../../image/icons/mail.svg'
import './index.less'

export default function AboutUs() {

    return (
        <>
            <div className='testimonial-banner'></div>
            <div className='testimonial-wrapper'>
                {/* Testimonial Section */}
                <div className='testimonial-section'>
                    <div className='name-section'>
                        <p className='name'>Mr Balakrishna Adiga</p>
                        <p><b>DATE:</b> 02 Jan 2020</p>
                    </div>
                    
                    <p className='place m-b10'>60years, Male, Bangalore, India</p>
                    <div className='phone-email m-b10'>
                        <p className='m-r40'><img src={Mobile} className='img-icon' /> <span>080-41723218</span></p>
                        <p><img src={Mail} className='img-icon' /> <span><a href="mailto:badiga@gmail.com">badiga@gmail.com</a></span></p>
                    </div>
                    <p className='study m-b10'>BE, MBA, IT industry consultant and private</p>
                    <p className='year m-b10'>YEAR OF YOUR FIRST VISIT TO SAMATVAM - JNANA SANJEEVINI: June 2011</p>
                    <p className='info m-b10'><b>YOUR TESTIMONIAL TOWARDS SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES.</b> It is difficult 
                        to call SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES a hospital or a clinic. It is more 
                        like a temple or even one's mother's home where all worries are forgotten. I have been going 
                        there for past 8 years and I have only positive experiences. Dr Srikanta as a person is epitome 
                        of positive thinking and healer supreme. His positive compassionate attitude has rubbed off to 
                        the entire staff. India needs more institutions like SAMATVAM - JNANA SANJEEVINI HEALTH CARE 
                        SERVICES and India will be a much better place.
                    </p>
                </div>

                {/* Testimonial Section */}
                <div className='testimonial-section'>
                    <div className='name-section'>
                        <p className='name'>Ms Ratan Gopinath</p>
                        <p><b>DATE:</b> 03 Feb 2020</p>
                    </div>
                    
                    <p className='place m-b10'>61years, Female, Bangalore, India</p>
                    {/* <div className='phone-email m-b10'>
                        <p className='m-r40'><img src={Mobile} className='img-icon' /> <span>080-41723218</span></p>
                        <p><img src={Mail} className='img-icon' /> <span><a href="mailto:badiga@gmail.com">badiga@gmail.com</a></span></p>
                    </div> */}
                    <p className='study m-b10'>BEd, PGDTE, Teacher</p>
                    <p className='year m-b10'>YEAR OF YOUR FIRST VISIT TO SAMATVAM - JNANA SANJEEVINI: 2002</p>
                    <p className='info m-b10'><b>YOUR TESTIMONIAL TOWARDS SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES. </b>
                        In the year 2002, I went through a bout of ill health and had to undergo a number of clinical 
                        investigations. They pointed to an endocrine problem and that is how I was referred to Dr. 
                        Srikanta and Samatvam. Further tests showed that I was suffering from a hypo-pituitary condition 
                        that required immediate medication and treatment. This was how I became a regular at 
                        Samatvam/Jnana Sanjeevini and Dr. Srikanta and his team became my caregivers and friends. Dr. 
                        Srikanta gave me some wonderful advice : “Just swallow the tablets that you have been prescribed, 
                        be peaceful and get on with your life”.  In the seventeen years that I have known him, I have always 
                        admired Dr. Srikanata’s thirst for knowledge (and its dissemination), his unique commitment to 
                        research and his desire to improve his patients’ lives in a holistic way. I am grateful to all at 
                        Jnana Sanjeevini for giving me the confidence that ill-health is a hiccup and that they are there to 
                        help me to deal with it.
                    </p>
                </div>

                {/* Testimonial Section */}
                <div className='testimonial-section'>
                    <div className='name-section'>
                        <p className='name'>Ms Usha Dhiman</p>
                        <p><b>DATE:</b> 10 Feb 2020</p>
                    </div>
                    
                    <p className='place m-b10'>66years, Female, NEW DELHI, India</p>
                    <div className='phone-email m-b10'>
                        <p className='m-r40'><img src={Mobile} className='img-icon' /> <span>9899052727</span></p>
                        <p><img src={Mail} className='img-icon' /> <span><a href="mailto:ushipaka@hotmail.com">ushipaka@hotmail.com</a></span></p>
                    </div>
                    <p className='study m-b10'>Graduation, Service</p>
                    <p className='year m-b10'>YEAR OF YOUR FIRST VISIT TO SAMATVAM - JNANA SANJEEVINI: November 2011 to 
                        receive gold medal for living with diabetes for more than 50 years.
                    </p>
                    <p className='info m-b10'><b>YOUR TESTIMONIAL TOWARDS SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES</b>
                        Samatvam is run on complete different ideals. Unlike other hospitals it is a place where the needy, poor and 
                        homeless are not only treated at par with other patients (like children from the nearby villages) but are 
                        specially given free education, consultations, free strips and even meters which they obviously cannot afford 
                        but which essential to keep their Type 1 diabetes under control.
                        <br></br>
                        <br></br>
                        Samatvam is run on charity given by the benevolent and generous people and business houses who 
                        know the great work being done by Dr. S.S. Srikanta and his excellent team. They work round the 
                        clock and so many times put work before self as a result missing meals and working beyond their 
                        hours. Knowledge is imparted to all and anyone can walk through the door and will be treated 
                        with love and devotion by the entire staff.  Samatvam follows the ideals of Mother Theresa, Sri 
                        Aurobindo and the Bhagvad Gita, and this reflects in the service they provide to mankind.
                        <br></br>
                        <br></br>
                        I have visited Samatvam a couple of times and was housed with one of their staff member who 
                        looked after me as a family member and made me feel so much at home. I have not seen such 
                        kindness, passion and selfless work done for the patients anywhere else. Especially the poor 
                        who cannot even afford food, and are shunned by the society, they find succor here at Samatvam.
                        <br></br>
                        <br></br>
                        I have always returned with awe on seeing the sincere and long hours put in by the staff and of 
                        course lead by Dr. S.S. Srikanta who forgets to eat and drink when he is engrossed with the 
                        patients, staff or holding various seminars and conference.
                        <br></br>
                        <br></br>
                        Pray God helps SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES reach milestones never achieved before.
                    </p>
                </div>

                {/* Testimonial Section */}
                <div className='testimonial-section m-b80'>
                    <div className='name-section'>
                        <p className='name'> Eng. Mugisha Hilary</p>
                        <p><b>DATE:</b> 16 Apr 2019</p>
                    </div>
                    
                    <p className='place m-b10'>43years, Male, MBARARA, UGANDA</p>
                    {/* <div className='phone-email m-b10'>
                        <p className='m-r40'><img src={Mobile} className='img-icon' /> <span>080-41723218</span></p>
                        <p><img src={Mail} className='img-icon' /> <span><a href="mailto:badiga@gmail.com">badiga@gmail.com</a></span></p>
                    </div> */}
                    <p className='study m-b10'>MSc (Urban Infrastructure Management), Civil Engineer</p>
                    <p className='year m-b10'>YEAR OF YOUR FIRST VISIT TO SAMATVAM - JNANA SANJEEVINI: September/2017 </p>
                    <p className='info m-b10'><b>YOUR TESTIMONIAL TOWARDS SAMATVAM - JNANA SANJEEVINI HEALTH CARE SERVICES. </b>
                        Namaste, I was diagnosed with suspicious thyroid nodular disease in Uganda in 2017 after 
                        undertaking numerous diagnostic tests, a total thyroidectomy (complete thyroid removal) was 
                        undertaken in 2 surgeries. After the surgeries, I received contradicting pathology reports with 
                        suspicions of malignancy ranging from follicular carcinoma to colloid goiter disease.
                        <br></br>
                        <br></br>
                        My doctors in Uganda with Prof Norlias from Cuba resolved to refer me to the only National Cancer Institute in 
                        Kampala for radiotherapy treatment considering the pathology discrepancies it was agreed to over treat than under 
                        treat with radiation therapy. The team at the Uganda Cancer Institute evaluated and approved the need for radiation 
                        therapy, unfortunately lacked the prerequisites to undertake the treatment. 
                        <br></br>
                        <br></br>
                        Thanks to my family, relatives and friends we mobilized resources in 2 weeks to travel and 
                        receive the missing treatment in Bangalore India. In India I received diagnostic interventions 
                        from many private facilities. Throughout the diagnostic processes in Bangalore Doctors kept 
                        referring my case to more senior doctors to handle, until I ended up in the hands of Dr Srikanta 
                        of Samatvan Jnana Sanjeevini.
                        <br></br>
                        <br></br>
                        Thank God for Dr Srikanta of Samatvan Jnana Sanjeevini who on top of systematic evaluation of my 
                        entire medical reports and prescribed the appropriate treatment, He provided the spiritual 
                        support to calm down the storm that had been boiling in my life for the whole year
                        <br></br>
                        <br></br>
                        Even during my subsequent visit in India for medical review in September 2018, contradicting FNAC report resurfaced 
                        but with expert teams analysis headed by Dr Shanta Krishnamurthy and Dr Srikanta I was saved from unwanted radiation 
                        therapy the second time.
                        <br></br>
                        <br></br>
                        Currently I’am health wise very okay undertaking my routine job and career activities while continuously 
                        participation in Rotary cancer fundraising especially the annual cancer Marathon sessions.
                        <br></br>
                        <br></br>
                        Many thanks and gratitude to the medical teams in Uganda and India especially Dr Srikanta of 
                        Samatvan Janna Sanjeevini Health services for the comprehensive evaluation of my complex medical 
                        condition, provision of appropriate treatment, emotional and  spiritual support.
                    </p>
                </div>

            </div>
        </>
    )
}
