import React from 'react'
import InsulinPump from '../../image/insulin-pump.png'
import ServicesAffiliations from '../../image/services-affiliatons.png'
import FacilitiesPatient from '../../image/facilities-patient.png'
import './index.less'

export default function Facilities() {

    return (
        <>
            <div className='facilities-banner'></div>
            <div className='facilities-wrapper'>
                <p className='main-title'>Diabetes Endocrinology Medicine</p>
                <ul className='wrap-list'>
                    <li>Out Patient – Day Care Services</li>
                    <li>In Patient Services</li>
                    <li>Patient Education Programs</li>
                    <li>Laboratory and Diagnostics Services</li>
                    <li>Telemedicine Services</li>
                </ul>

                <p className='title'>Out Patient – Day Care Services: 2018 - 2019</p>
                <p className='sub-title'>Out Patients – Day Care, Jnana Sanjeevini, JP Nagar</p>
                <ul className='wrap-list'>
                    <li>Endocrinology Diabetes > 10000</li>
                    <li>Ophthalmology > 650</li>
                    <li>Podiatry Foot Care > 200</li>
                    <li>Psychiatry > 300</li>
                    <li>General Medicine</li>
                    <li>General Surgery</li>
                </ul>

                <p className='sub-title'>Out Patients – Day Care, Samatvam Endocrinology Diabetes Centers</p>
                <ul className='wrap-list'>
                    <li>Samatvam Jayanagar > 3000</li>
                    <li>Samatvam Indiranagar > 6000</li>
                    <li>Samatvam BTM 1 > 800</li>
                    <li>Samatvam BTM 2 > 1800</li>
                </ul>

                <p className='sub-title'>Speciality Diabetes Clinics</p>
                <ul className='wrap-list'>
                    <li>Paediatric Diabetes</li>
                    <li>Pregnancy Diabetes</li>
                    <li>Healthy Heart</li>
                    <li>Insulin Perfection</li>
                    <ul>
                        <li className='some-full-list'>Continuous Glucose Monitoring > 400</li>
                        <li className='some-full-list'>Insulin Pumps > 100</li>
                    </ul>
                </ul>

                <p className='sub-title'>Speciality Endocrinology Clinics</p>
                <div className='wrapper-with-img'>
                    <ul>
                        <li>Thyroid</li>
                        <li>Obesity</li>
                        <li>Bone Health</li>
                        <li>Paediatric Endo</li>
                        <li>Reproductive Endo</li>
                        <li>Pituitary Adrenal</li>
                    </ul>
                    <div className='img-wrapper'>
                        <img src={InsulinPump} />
                    </div>
                </div>

                <p className='sub-title'>Out Patients - Day Care: Affiliated Hosp</p>
                <ul className='wrap-list'>
                    <li>NIMHANS > 1000</li>
                    <li>Sri Krishna Sevashrama > 2000</li>
                    <li>Rangadore > 1000</li>
                    <li>Sagar > 400</li>
                    <li>Vikram > 6000</li>
                    <li>Sparsh > 2000</li>
                    <li>Chinmaya Mission > 2400</li>
                    <li>Cloud Nine > 1400</li>
                    <li>Apollo > 2400</li>
                </ul>

                <p className='title'>In Patient – Emergency Services: 2018 - 2019</p>
                <p className='sub-title'>In Patients Emergency: Affiliated Hospitals</p>
                <div className='wrapper-with-img'>
                    <ul>
                        <li>Shanthi > 100</li>
                        <li>Sagar > 100</li>
                        <li>Vikram >  4000</li>
                        <li>Sparsh > 600</li>
                        <li>NIMHANS > 400</li>
                        <li>Apollo > 480</li>
                        <li>Excel Care > 20</li>
                    </ul>
                    <div className='img-wrapper'>
                        <img src={ServicesAffiliations} />
                    </div>
                </div>
                <p>Samatvam Patients, Cross Referrals, Intensive Critical Care, Post - Operative Care, Multidisciplinary Care</p>


                <p className='title'>Health Education - Counselling</p>
                <p className='sub-title'>Counselling - Self Care</p>
                <ul className='wrap-list'>
                    <li>Individual</li> 
                    <li>Family</li> 
                    <li>Group</li> 
                    <ul>
                        <li className='some-full-list'>To be rekindled</li>
                    </ul>
                    <li>General Public</li> 
                    <ul>
                        <li className='some-full-list'>Camps, Seminars</li>
                    </ul>
                    <li>Mass Media</li>
                    <ul>
                        <li className='some-full-list'>Print, Electronic</li>
                    </ul>
                </ul>

                <p className='sub-title'>Patient Education Resources</p>
                <ul className='wrap-list'>
                    <li>Diabetes Teaching Guides</li>
                    <ul>
                        <li className='some-full-list'>Kannada, English, Hindi</li>
                    </ul>
                    <li>Heart Health</li>
                    <li>Thyroid</li>
                    <li>Bone Health</li>
                    <ul>
                        <li className='some-full-list'>Osteoporosis</li>
                    </ul>
                    <li>Others</li>
                    <ul>
                        <li className='some-full-list'>Renal Diet, GERD, etc</li>
                    </ul>
                </ul>
                <div className='facilities-patient'>
                    <img src={FacilitiesPatient} />
                </div>

                <p className='sub-title'>Diagnostic Services</p>
                <p><b>Laboratory</b></p>
                <ul className='wrap-list'>
                    <li>In House: Limited – Point of Care Testing</li>
                    <li>[Rest Speciality Referral Labs]</li>
                    <li>Clinical Chemistry > 3000</li>
                    <li>Hormone Endocrinology > 3000</li>
                    <li>Haematology > 3000</li>
                    <li>Immunology > 1000</li>
                </ul>
                
                <p><b>Cardiology</b></p>
                <ul className='wrap-list'>
                    <li>ECGs > 4000</li>
                    <li>ABPM (Ambulatory BP monitoring ) > 20</li>
                    <li>ECHO > 150</li>
                    <li>TMT</li>
                </ul>

                <p><b>Radiology</b></p>
                <ul className='wrap-list'>
                    <li>X ray</li>
                    <li>Ultrasound > 300</li>
                </ul>

                <p><b>Pulmonary</b></p>                    
                <ul>
                    <li>Home Sleep Study > 30</li>
                </ul>

                <p className='sub-title'>Telemedicine – Telecounselling Services</p>
                <p><b>World’s largest 24 x 7 helplines: Since 1994 FREE</b></p>
                <ul className='wrap-list m-b80'>
                    <li>Telephone, Whats app, Emails etc</li>
                    <li>2018 – 2019 > 50000 encounters</li>
                    <li>Doctors</li>
                    <li>Counsellors</li>
                    <li>Karnataka State, Out of State and International calls</li>
                    <ul>
                        <li className='some-full-list'>Emergency Diabetes Care, Emergency Hospital Admissions; General Queries</li>
                    </ul>
                </ul>
                   

            </div>
        </>
    )
}