import React from 'react'
import PDF1 from "../../image/newsletter/Samatvam-Jnana-Sanjeevini.pdf"
import PDF2 from "../../image/newsletter/PREFINAL-SAM-DIABETES.pdf"
import PDF3 from "../../image/newsletter/JAN-MARCH-2019-SAMATVAM.pdf"
import PDF4 from "../../image/newsletter/SAMATVAM-SWASTHYA-APR-JUNE-2019.pdf"
import PDF5 from "../../image/newsletter/RESEARCH-SAMATVAM.pdf"
import PDF6 from "../../image/newsletter/SANJEEVINI-NIDHI-Charity.pdf"
import './index.less'

export default function NewsLetter() {

    return (
        <>
            <div className='newsletter-banner'></div>
            <div className='newsletter-wrapper'>
                <p className='main-title'>Our Latest NewsLetter:</p>
                <ul>
                    <li><a href={PDF1} target="_blank" rel="noopener noreferrer">Samatvam Jnana Sanjeevini</a></li>
                    <li><a href={PDF2} target="_blank" rel="noopener noreferrer">Prefinal Sam Diabetes</a></li>
                    <li><a href={PDF3} target="_blank" rel="noopener noreferrer">Jan - March 2019 Samatvam </a></li>
                    <li><a href={PDF4} target="_blank" rel="noopener noreferrer">Samatvam Swasthya Apr - Jun 2019</a></li>
                    <li><a href={PDF5} target="_blank" rel="noopener noreferrer">Research</a></li>
                    <li><a href={PDF6} target="_blank" rel="noopener noreferrer">Charity</a></li>
                </ul>      
            </div>
        </>
    )
}