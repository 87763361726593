import React from 'react';
import { Modal } from 'antd';
import {Form, Input, Button} from '../Base/Layout.js';
import ContactMobile from '../../image/contact-mobile.svg'
import { useHistory } from "react-router-dom";

import './index.less'

export default function ContactUsForm() {
    const [visible, setVisible] = React.useState(false);
    console.log("ContactUsForm -> visible", visible)
    let history = useHistory();

    // Fecth Post call
    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response; // parses JSON response into native JavaScript objects
      }

    const onFinish = values => {
        const payload = {"toEmails":[ "suprabhasupi@gmail.com"], "subject": `Mail from Samatvam by ${values.name}!`,"message":`<!DOCTYPE html> <html> <body> <p>You got a new mail from Samatvam.org with user info as:</p> <ul> <li><b>Name:</b> ${values.name}</li> <li><b>Mobile:</b> ${values.mobile}</li> <li><b>Email:</b> ${values.email}</li> <li><b>Message:</b> ${values.message}</li> </ul> <p>Thank You,</p> <p>Admin Team</p> </body> </html>`}
        postData('https://p9pjie9645.execute-api.ap-south-1.amazonaws.com/Prod/send', payload)
        .then(data => {
            setVisible(true);
            setTimeout(() => {
                history.push('/')
            }, 2000)
        });
        
    };
    const handleCancel = e => {
        setVisible(false)
		console.log(e);
	};

    // const onFinishFailed = errorInfo => {
    //     console.log('Failed:', errorInfo);
    // };x`

    return (
        <div className='contact-us-form'>
            <Form onFinish={onFinish}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter name!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[{ required: true, message: 'Please enter mobile number!' }]}
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please enter email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Message"
                    name="message"
                    rules={[{ required: true, message: 'Please enter message!' }]}
                >
                    <Input />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Submit
                </Button>

            </Form>
            <img src={ContactMobile} className='contact-us-img' />

            <Modal visible={visible} onCancel={handleCancel}>
                <p className='main-title ta-center orange'>Thanks You!</p>
                <p className='thanks-info'>We will get back to you soon.</p>
            </Modal>
        </div>
    )


}