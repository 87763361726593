import React from 'react'
import DiabetesCharge from '../../image/diabetes-charge.png'
import './index.less'

export default function Diabetes() {

return (
    <>
        <div className='diabetes-banner'></div>
        <div className='diabetes-wrapper'>
            <p className='main-title'>Stop Diabetes Win Health</p>
            <p>As a specialized Diabetes Centers, Samatvam – Jnana Sanjeevini provide state of the art comprehensive health care to those 
                living with diabetes. The Diabetes Centers have something to offer everyone, whether you have type 1 or type 2 diabetes, 
                or gestational diabetes; have experienced diabetes-related complications including eye, kidney, nerve or vascular 
                disease (heart attack, stroke), foot problems and gangrene etc; or have lifestyle considerations requiring tailored therapy 
                to achieve your long-term goals. The Diabetes Centers have a knowledgeable team of providers whose experience caring for 
                and managing diabetes spans over several decades.   Diabetologists (specialist physicians with expert training and 
                certification in managing diabetes and its complications) and Certified Diabetes Educators are raising the level of diabetes 
                management throughout the community, providing educational services to patients and their families.
            </p>

            <p className='sub-title'>Power of Prevention</p>
            <p>A full and productive life is possible despite diabetes. Diabetes and its complications are preventable 
                (Heart attacks, strokes, blindness, kidney failure, gangrene / leg amputations, etc). Heart attacks, angioplasties and 
                bypass surgeries are preventable; to a major extent. 
            </p>
            <p className='highlight'>The answer is in your hands … and Samatvam – Jnana Sanjeevini is there to help you.</p>

            <p className='sub-title'>Diabetes Day Care Unit</p>
            <p>Diabetes Day Care Unit currently provides two special health care programs targeted to help people with diabetes and their 
                families to obtain the best in comprehensive and advanced diabetes health care. These programs are administered by a ‘team’ 
                of highly experienced diabetologists, dieticians and nurse educators supported by ultramodern laboratory services and super 
                specialty consultant referrals.
            </p>

            <p className='sub-title'>Diabetes Annual Health Review</p>
            <ul>
                <li><span className='highlight'>Program:</span> During the course of single day, complete physical examination and investigations for diabetes, blood glucose 
                    control and associated problems (heart, kidneys, eyes, feet etc.), evaluation and counselling by dieticians and diabetes 
                    educators, and review and treatment guidance by expert diabetologists are provided.
                </li>
                <li><span className='highlight'>Participation:</span> Beneficial to all people with diabetes once a year ('Annual Review’ and ‘Follow up’)</li>
                <li><span className='highlight'>Dates and Time:</span> Weekdays (Mon to Sat)</li>
            </ul>

            <p className='sub-title'>Diabetes Self Care Basic and Intensive</p>
            <div className='d-f'>
                <ul className='m-b80'>
                    <li><span className='highlight'>Program:</span> Basic and intensive diabetes health education and self-care training program for the patients and family, 
                        including lectures, demonstrations, discussions and guidance on various practical aspects of health care; the nature of 
                        diabetes, its proper management in terms of meal planning, physical activity, insulin injections and oral drugs, newer 
                        diabetes medicines, insulin pumps and continuous glucose monitoring special situations and emergencies (hypoglycemia, 
                        ketoacidosis, sick day guidelines), self monitoring of diabetes (urine and blood glucose testing), prevention of 
                        complications (heart, kidneys, eyes, feet, infections etc.); supported by easy to understand and follow learning source 
                        materials, booklets and video.
                    </li>
                    <li><span className='highlight'>Participation:</span> Beneficial to all ‘new’ diabetes patients and those eager to learn more about self management of 
                        diabetes (’Initial review’ and ‘Enrichment’).
                    </li>
                    <li><span className='highlight'>General Diabetes Outpatient Clinics</span></li>
                    <li><span className='highlight'>Speciality Diabetes Outpatient Clinics</span></li>
                </ul>
                <div className='diabetes-img'>
                    <img src={DiabetesCharge} />
                </div>
            </div>
{/* 
            <h2>Kavitha and team to trim and edit</h2>
            <p>Outpatient clinics</p>
            <p>Outpatient clinics carry out check-ups for people with diabetes and other endocrine metabolic disorders. Approximately 10000 
                patients attend these outpatient check-ups, on average four times a year. Endocrinologists Diabetologists, physicians, 
                ophthalmologists, diabetes specialist counselors, clinical dieticians, specialist nurses, medical secretaries, podiatrists, 
                and social counsellors serve  in the outpatient clinics.
            </p>
            
            <p>The main functions of the outpatient clinics:</p>
            <ul>
                <li>To start up insulin treatment in persons with newly diagnosed type 1 diabetes</li>
                <li>To maintain lifelong contact with persons with type 1 diabetes</li>
                <li>To start up nutrition, exercise and medication treatment in persons with newly diagnosed type 2 diabetes:
                    <ul>
                        <li>To establish and maintain lifelong contact with persons with type 2 diabetes with the objective of preventing and 
                            managing complications
                        </li>
                        <li>To diagnose and treat patients with diverse endocrine metabolic disorders, most of which need life long treatment.</li>
                    </ul>
                </li>
            </ul>

            <p>Diabetes team</p>
            <p>All patients who attend the outpatient clinics at Samatvam are attached to a diabetes care team. Each care team is made up of 
                two physicians (a chief endocrinologist diabetologist and a senior house officer or specialist registrar / fellow), 2 -4 
                diabetes specialist counselors / physician assistants, a clinical dietician, a clinical assistant,  and a medical secretary.
            </p>
            
            <p>The purposes of the diabetes care team</p>
            <ul>
                <li>To ensure each patient is attached to a care team</li>
                <li>To provide continuity of care – ideally, patients should always be examined by the same person; if this is not possible, 
                    another member of the same team will carry out the examination
                </li>
                <li>To make team decisions concerning the management of the patient’s diabetes</li>
            </ul>

            <p>Specialist clinics</p>
            <p>The main outpatient clinics also have a number of smaller specialist clinics. These clinics have their own specified timings, 
                within the main clinic working hours. In addition to the various clinics and departments for examinations and treatment, 
                the outpatient clinics offer counseling, and dietary advice and support.
            </p>

            <p>Specialist clinics</p>
            <ul>
                <li>The pregnancy clinic</li>
                <li>The pump clinic</li>
                <li>The type 2 clilnic</li>
                <li>The children and youth clinic</li>
            </ul>

            <p>Counselling, advice and support</p>
            <ul>
                <li>Diabetes specialist counsellor</li>
                <li>Clinical dietician</li>
                <li>Social counsellor</li>
            </ul>

            <p>Clinics for examination and treatment</p>
            <ul>
                <li>The foot clinic</li>
                <li>The eye clinic</li>
                <li>Clinical physiology</li>
                <li>Clinical biochemistry</li>
                <li>Endocrine metabolic laboratory</li>
            </ul>

            <p>The pump clinic</p>
            <p>Presentation coming soon.</p>

            <p>The pregnancy clinic</p>
            <p>The pregnancy clinic runs once a week, on Saturday mornings. Here, women with diabetes who are pregnant, or planning a pregnancy, 
                can seek the advice of a care team made up of a chief endocrinologist diabetologist, a diabetes specialist counsellor and a 
                clinical dietician. The care covers both type 1 and type 2 diabetes. The care team manages the treatment of the diabetes 
                during the pregnancy, while the other routine examinations are carried out at the antenatal clinic.
            </p>

            <p>Pregnancy planning</p>
            <p>Right at the start of planning a pregnancy, in the pre-conception stage, doctors and nurses are available to advise woman on 
                what being pregnant and having diabetes involves, in particular, what the HbA1c, the blood glucose level, should be around 
                the time of conception. The aim of pregnancy planning is to avoid unplanned pregnancies where diabetes management has not 
                been considered. For women with type 2 diabetes who are planning a pregnancy, tablet treatment may be replaced by insulin 
                injections.  Other medication is checked and changed if necessary.
            </p>

            <p>Risk prevention</p>
            <p>Women with diabetes have a higher risk of developing pre-eclampsia, giving birth prematurely and giving birth to very large 
                infants. In rare case, fetal malformations occur. The risk of complications can be decreased by careful regulation of blood 
                glucose (e.g. by monitoring levels at least four times a day) and by maintaining normal blood pressure.
            </p>

            <p>Check-ups in the pregnancy clinic</p>
            <p>Women are referred to the pregnancy care clinic once the pregnancy has been confirmed. They are offered an eye examination 
                (retinal examination) and a urine- and blood test. These examinations are repeated in the 26th week of pregnancy and again two 
                months after the woman has given birth. From the very start of the pregnancy, women are encouraged to keep a pregnancy diary and to 
                monitor their blood glucose four times a day.
            </p>

            <p>A typical visit</p>
            <p>At every visit the woman’s blood pressure and blood glucose (Hba1c) are measured and an assessment is made of albuminuria 
                (protein in urine). The woman’s self-monitored blood glucose levels are checked by either a physician or nurse, and treatment 
                is adjusted accordingly. The diabetes team give any necessary information and advice related to the pregnancy, in particular 
                concerning how reactions to and the need for insulin change during the course of a pregnancy. Women are shown how to adjust 
                their insulin dose in line with their blood glucose reading, and are encouraged to discuss any care or dietary concerns they 
                may have.
            </p>

            <p>Post-natal visits</p>
            <p>Two months after giving birth, they have a last check-up at the pregnancy clinic before being signed off. The forthcoming check-ups are 
                done in the general outpatient clinics.
            </p>

            <p>The children and youth clinic</p>
            <p>The children and youth clinic, for young people upto 25 years of age with diabetes, is part of the outpatient clinics. It runs once 
                a month on the first Sunday from 9.00 am to 12.00 noon.  people meet to share experiences and make new friends.
            </p>

            <p>Childern with diabetes</p>
            <p>Infancy and childhood present special difficulties to the patients and their parents. Intensive insulin therapy may not be feasible and 
                certain targets need to be relaxed. Adjustments at school and play need special attention. Self care responsibilities needs to be 
                slowly transferred to the child based on its capabilities and accomplishements.  Most day to day treatment decisions may still lie 
                with the parents. The team includes a pediatrician for general health care.
            </p>

            <p>Young people with diabetes</p>
            <p>Puberty can present particular difficulties for young people with diabetes. The need for insulin increases and the physical 
                and psychosocial changes in the youngsters’ lives mean that the diabetes treatment needs to be frequently adjusted. 
                The clinic was set up to help adolescents and their parents with the problems specifically related to diabetes during puberty. 
                It has a team of specialist practitioners, and each young person has his or her personal practitioner.
            </p>

            <p>Taking responsibility for their own treatment</p>
            <p>The practitioners in the multidisciplinary team work towards getting the young people to accept they do have an illness, but that it 
                can be treated. They aim to gain the youngsters’ confidence and to motivate them to take responsibility for their lives, especially 
                in relation to diabetes.  Education in diabetes, its symptoms and treatment; and instruction in how to inject themselves and monitor 
                blood glucose is aimed at empowering the youngsters to take responsibility and make informed decisions about their treatment.
            </p>

            <p>Events</p>
            <p>During the opening hours of the clinic, usually around  11.00 am, the practitioners put on an “event”. The event deals with 
                topics relevant to youngsters with diabetes, for example, hypoglycaemia, parties, achievements in school and sports, and 
                subsidies for blood glucose monitoring apparatus. Often, sweets and potato crisps are handed round; this paves the way for a 
                discussion about confectionery and other snacks.
            </p>

            <p>Consultations and other arrangements</p>
            <p>Parents attend the consultation when considered necessary, usually only the first few times. The clinic arranges an annual 
                meeting for adult relatives; this begins with a diabetes-related talk, which is followed by a discussion.
            </p>

            <p>The type 2 clinic</p>
            <p>The type 2 clinic is part of the outpatient clinics, and its team, consists of an endocrinologist diabetologist, a senior house officer, 
                registrar or fellow, 2 – 4  diabetes specialist counsellors, a clinical dietician and a medical secretary. The responsibilities 
                of the type 2 clinic cover education, developing treatment management plans and carrying out status investigations in patients 
                with newly diagnosed type 2 diabetes who have been referred to the clinic by their own general practitioner.
            </p>

            <p>The overall purpose of the clinic</p>
            <p>To contribute to improving the quality of life and extending the life expectancy of persons with type 2 diabetes through 
                prevention of acute and chronic diabetic complications.
            </p>

            <p>The primary functions of the type 2 clinic:</p>
            <ul>
                <li>To provide education, treatment and status on possible diabetic complications</li>
                <li>To provide education and practical instruction on insulin treatment.</li>
                <li>To provide intensive care management for persons with type 2 diabetes with severe diabetic complications</li>
            </ul>

            <p>The clinic’s focus areas:</p>
            <ul>
                <li>Lifestyle changes: increased physical activity, smoking cessation, weight loss </li>
                <li>Dietary changes</li>
                <li>Setting personal diabetes-management goals</li>
                <li>Involvement in working with problem areas</li>
            </ul>

            <h1>Treatment in the type 2 clinic</h1>
            <p>The treatment course</p>
            <p>Treatment spans approximately 8 months and is split into A, B, C and D visits, see below. A specialist diabetes nurse coordinates and 
                supervises the visits. After the treatment has ended, all further check-ups are carried out in coordination with the patient’s own 
                general practitioner
            </p>
            <ul>
                <li>A-visits – Initial consultation with the diabetes specialist counselor and diabetes registrar or fellow.</li>
                <li>B-visits – Consultation with the endocrinologist diabetologist to establish the patient’s medical record, repeat consultation with the 
                    diabetes specialist counselor and the clinical dietician.
                </li>
                <li>C-visits – A half day education course with group-based teaching, with special emphasis on diabetes, late diabetic 
                    complications and medical treatment. Blood glucose is monitored and a urine test is taken. Retinal photography at the eye 
                    clinic is conducted.    In addition, information is given concerning the principles of a healthy diet, alcohol, smoking, 
                    physical exercise and preventive footcare. The legal rights of persons with diabetes and available support are discussed. 
                    A follow-up consultation with the diabetes specialist counsellor also takes place
                </li>
                <li>D-visits – End of treatment and status. The half day course days are a month apart (2nd or 4th Saturday of the month). 
                    Each course has about 20 participants. Participants are encouraged to share their experiences of living with diabetes, 
                    ask questions and explore areas where they feel they lack information. Early on in the proceedings, each participant is 
                    asked to consider his or her lifestyle and to create personal diabetes-management goals for the changes he or she needs to 
                    work towards.
                </li>
            </ul> */}

            {/* <p>The course facilitators</p>
            <p>A diabetes specialist counselor is responsible for the teaching concerning the diabetes illness, its complications and the 
                medical treatment. They also give instruction on blood glucose monitoring and urine testing for glucose levels.
            </p>
            <ul>
                <li>A clinical dietician teaches the principles of a healthy diabetic diet and provides information about alcohol, 
                    smoking and physical exercise
                </li>
                <li>A podiatrist teaches about the importance preventive footcare</li>
                <li>A social counsellor informs participants about their legal rights and about available subsidies and support.</li>
            </ul>

            <p>Focus of the education</p>
            <p>All course facilitators put special emphasis on the need to make lifestyle changes and revise habits</p>
            <p>The concluding visit entails a consultation with the endocrinologist diabetologist, a diabetes specialist counsellor and a clinical 
                dietician, and a status is made. The effect of the treatment is assessed and the lifestyle scheme adjusted to allow the person with 
                diabetes to clearly see what has been achieved and what must be worked towards. Few people need further monitoring in the outpatient 
                clinics.
            </p>

            <p>Course in outpatient insulin treatment start-up</p>
            <p>If lifestyle changes and treatment with tablets do not provide satisfactory regulation of the blood glucose (HbA1c remaining over 6.5%), 
                the treatment may be supplemented with insulin injections. Depending on the blood glucose profile, insulin is administered with meals or 
                before bedtime. Courses are frequently run to teach patients how to inject themselves with insulin; theoretical and practical instruction 
                is given by a diabetes specialist counselor  and a clinical dietician. Courses are for 4 to 6 participants, and relatives may attend if 
                the patient wishes.
            </p>

            <h1>Preparation</h1>
            
            <p>The course content</p>
            <p>The 4-hour course starts with a clinical dietician giving participants information about the principles of a healthy diabetic diet, 
                and this is followed by a nurse teaching:
            </p>
            <ul>
                <li>The construction and function of the insulin pen</li>
                <li>Injection technique</li>
                <li>Storage and destruction of the pen and needles/syringes</li>
                <li>Hypoglycaemia – its symptoms and management</li>
                <li>Hyperglycaemia – its symptoms and management</li>
                <li>Monitoring blood glucose</li>
                <li>Precautions – travelling, social events, alcohol and illness</li>
            </ul>
            <p>The nurse provides explanations and instructions about taking insulin backed up by detailed handouts. On this course, participants have 
                the opportunity to practise injecting themselves.
            </p>

            <p>Follow-up</p>
            <p>After the course, the patient and nurse keep in close telephone contact during the next few days, and the patient is given a 
                new appointment at the outpatient clinic. This arrangement allows any problems experienced by the patient to be dealt with, and 
                the effect of the insulin treatment can be assessed and adjusted. If problems arise outside the clinic’s opening hours, 
                telephone calls are redirected to a round-the-clock telephone service answered by a diabetes specialist counselor.
            </p>

            <p>Counselling</p>
            <p>Members of the various professional groups offer counselling within their specialist area. Appointments must be made. See the list of 
                health professionals in the menu on the left.
            </p>
            <p>Counsellors take the responsibility to  test the quality of devices used to monitor blood glucose levels and to teach persons 
                with diabetes how to use them. A wide range of commercially available blood glucose monitoring devices are available.
            </p>

            <p>Trying out blood glucose monitoring devices</p>
            <p>The Counsellors  have detailed knowledge about the range of available blood glucose monitoring devices. Patients receive advice tailored 
                to their specific needs about which device to buy and under the guidance of a counselor , have the opportunity of trying out the 
                various apparatus
            </p>

            <p>Checking blood glucose monitoring devices</p>
            <p>The laboratory recommends that all blood glucose monitoring devices are checked once a year, or more often if faulty measurements are 
                suspected. When the devices are checked, a counselor  takes a blood sample for analysis and checks how the person with diabetes does 
                his or her self-monitoring. The results of the two samples are compared and if a significant difference is apparent, the device is 
                returned to the producer to be examined for defects.
            </p>
            <p>Every new, commercially available, blood glucose monitoring device is tested to assess its quality and to determine whether 
                its functionality and instructions for use are user friendly. Quality is assessed by comparing the results of samples taken 
                using the new device with those of samples taken by the clinical biochemistry department. The counselors test the new device 
                and assess the likelihood of errors.
            </p>

            <p>The loan of blood glucose monitoring devices</p>
            <p>Blood glucose monitoring devices can be borrowed for short periods from the counselors, and following authorisation from a member of 
                the care team devices are also available for home use. Patients are shown how to use the device and are given forms on which to register 
                the readings.
            </p>

            <p>Clinical dietician</p>
            <p>Samatvam has the services of several  clinical dieticians; they are attached to the multidisciplinary care team. Their job is to give 
                advice and education about lifestyle changes: diet, physical exercise, alcohol and tobacco are just some of the areas covered. 
                The dieticians work in the outpatient clinics, the type 2 clinic, the pregnancy clinic, the children and youth clinic, and the diabetes 
                units of affiliated hospitals.
            </p>

            <p>Diet and diabetes</p>
            <p>Diet is a central component in treating diabetes, and the clinical dieticians are there to explain about the nutritional and chemical 
                composition of different foods and the importance of knowing how to combine these foods. Information is also given on alcohol. Both 
                the composition and the amount of food are key factors in a diabetic diet; the dieticians help patients come to terms with this by 
                providing menu ideas and tips.
            </p>

            <p>The goals of dietary advice</p>
            <p>To ensure the person with diabetes:</p>
            <ul>
                <li>Maintains a blood glucose level as close as possible to normal</li>
                <li>Reaches and maintains an acceptable weight</li>
                <li>Can prevent or delay the development of complications</li>
            </ul>

            <p>All dietary advice and support takes the individual’s lifestyle and eating habits into consideration.</p>
            
            <p>Dietary advice may cover:</p>
            <ul>
                <li>Cholesterol count</li>
                <li>Fluctuating blood glucose levels</li>
                <li>Weight loss</li>
                <li>Weight gain</li>
                <li>Renal function</li>
                <li>Digestion problems</li>
                <li>Food and physical exercise</li>
                <li>Smoking cessation</li>
            </ul>

            <p>Education</p>
            <p>The clinical dieticians hold courses in the type 2 clinic and on the outpatient ward; teaching is based on the participants’ needs. 
                Courses are also run outside Samatvam for patient groups and other healthcare professionals.
            </p>

            <p>Social counsellilng</p>
            <p>Diabetes is a chronic illness that may lead to social problems for some patients. Coping with these problems and their consequences can 
                be difficult. Counsellors are there to help patients get an overview of the situation and to find ways of dealing with the difficulties, 
                perhaps with the help of social legislation
            </p>

            <p>The duties of the social counsellor</p>
            <p>Samatvam has several  social counsellors and all patients connected to the centre are entitled to seek help from him or her. The social 
                counsellor’s work lies primarily in giving information and counselling and, to a lesser extent, in teaching on the outpatient ward and 
                in the clinic. Most appointments with the social counsellor are made over the telephone or following referral from a practitioner, 
                but sometimes people just drop by after a consultation with one of the practitioners.
            </p>

            <p>The meeting with the social counsellor</p>
            <p>Some people contact the counsellor for advice on a specific problem; others come with situations they feel unable to cope with. 
                Mutual trust is an important element of these meetings and the counsellor works hard to establish this
            </p>
            <p>The counsellor listens to a person’s concerns and helps put the situation in perspective, and, if required, offers supportive advice to 
                ease the predicament. However, emphasis is on empowering the persons to solve the problems themselves. Often, problems cannot be 
                solved by a single meeting and counsellor and client meet several times; between meetings both parties work towards finding possible 
                solutions.
            </p>

            <p>Typical problem areas</p>
            <p>The majority of questions put to the counsellor are about employment conditions, legislation regarding subsidies and social problems.</p>

            <p>The foot clinic</p>
            <p>The foot clinic is staffed by one podiatrist, a physician foot specialist,  and two general surgeons with experience in diabetes foot 
                surgery, who have a weekly consultation. The surgeons examine and treat people with special diabetes-related foot problems. Vascular 
                surgery referrals are obtained as per need.
            </p>

            <p>Diabetes and the feet</p>
            <p>People with diabetes may experience complications in the form of nerve damage (neuropathy), reduced circulation to the feet or a 
                combination of both. Changes in the skin’s elasticity and moisture content may occur as well as changes in the muscular function. 
                These conditions require preventive footcare and regular examination of both feet and footwear to avoid problems. Proper guidance and 
                education in footcare is therefore crucial to understanding and preventing complications.
            </p>

            <p>Aims</p>
            <p>In addition to improving the overall quality of life of people with diabetes, the foot clinic aims:</p>
            <ul>
                <li>to detect neuropathy and changes in the blood vessels sufficiently early to prevent foot ulcers</li>
                <li>to reduce the number of major amputations</li>
                <li>to reduce the number of new amputations in those patients who have already had an amputation</li>
            </ul>

            <p>Check-ups in the foot clinic</p>
            <p>All patients with current or potential foot problems and/or symptoms of complications such as neuropathy or circulatory problems are referred to the foot clinic.</p>
            <p>Patients are examined to assess the extent of the problem: blood samples, x-rays of the feet and swabs of wounds are taken, and the 
                blood flow in the legs and feet is checked. Those who are not at risk of developing complications are referred to a local podiatrist.
            </p>

            <p>What the podiatrist looks for</p>
            <p>The podiatrist examines the condition of the feet. This includes an examination of the blood flow to the feet, the sense of feeling in 
                the feet and whether the patient has any skin problems. Based on these observations, the podiatrist advises on prevention, relief and 
                treatment.
            </p>
            <p>In a typical examination:</p>
            <ul>
                <li>the feet are examined for pressure sores, hard skin and cracks</li>
                <li>the shape and appearance of the toenails are examined</li>
                <li>the feet and toes are examined for incorrect positioning</li>
                <li>foot pulses are checked</li>
                <li>the sensitivity of the feet is checked</li>
                <li>the patient’s gait is observed</li>
                <li>the patient’s footwear is checked</li>
            </ul>

            <p>Prevention and treatment</p>
            <p>The podiatrist</p>
            <ul>
                <li>initiates and follows up treatment</li>
                <li>advises on proper footcare management</li>
                <li>tries to motivate a change towards more foot-friendly footwear</li>
                <li>alleviates discomfort by providing insoles for the patient’s own shoes, special footwear or custom-moulded shoes</li>
                <li>promotes mobility by fitting walking braces</li>
                <li>tries to prevent small wounds and infections from becoming ulcers</li>
                <li>in the case of loss of feeling, a template of the foot is made for use when buying new footwear</li>
                <li>in the case of foot ulcers, a wound-management plan is made</li>
                <li>in the case of complicated and infected foot ulcers admission to the outpatient ward may be necessary</li>
            </ul>
            <p>The patient’s diabetes management plan is checked and adjusted if the blood glucose level is too high. A high blood glucose level 
                will slow down the speed at which wounds heal.
            </p>

            <p>The eye clinic</p>
            <p>The eye clinic screens for diabetes-related changes in the eye (diabetic retinopathy). These changes often occur without any symptoms. 
                Early detection and diagnoses is important in order to begin treatment as soon as possible: early treatment may delay or even prevent 
                some diabetes-related changes. Treatment is based on regulating high levels of blood glucose and blood pressure, but for some patients 
                laser treatment is also necessary.
            </p>

            <p>The eye-clinic staff</p>
            <p>The clinic is staffed by ophthalmologists  who are clinical specialists in eye diseases. An ophthalmologist visits the clinic twice a 
                week to study the eye photographs and carry out  clinical check-ups (following referral).
            </p>

            <p>The eye consultation</p>
            <p>The consultation with the ophthalmologist  includes an eye test and examining / photographing of the retina in both eyes; the 
                patient is also given information about how the eye can change as a result of diabetes. The patient’s vision is assessed in 
                relation to the impact of diabetes and other eye diseases are discussed. Before the consultation the patient’s eyes are dripped 
                with eye drops that act as a local anaesthetic and also enlarge the pupils. This is done to obtain the best possible picture 
                quality. The assistant  takes five pictures of each retina with a digital camera. These pictures are then assembled in one 
                picture, and can be seen immediately.
            </p>

            <p>The results of the eye pictures</p>
            <p>Most people get the preliminary results of their eye pictures immediately after the photographs have been taken; however, 
                the final results are given at a later consultation, after the assistant  has made a thorough evaluation of the condition of the 
                retina, sometimes in collaboration with the ophthalmologist
            </p>

            <p>Annual check-ups</p>
            <p>Data on the condition of the retina are stored in a computer program along with all other data concerning the patient’s diabetes 
                monitoring. Based on this information, the program calculates the date of the next check-up. The assistant  then takes this date and 
                relates it to the patient’s current level of vision, history of diabetes and the result of the pictures from the previous check-up 
                before determining the actual date of the next check-up. Check-ups are carried out every 3 – 24 months, depending on the severity of 
                any changes in the retina.
            </p>

            <p>Clinical physiology</p>
            <p>The clinical physiology department carries out examinations to either detect and manage diabetic complications, or to determine a diagnosis.</p>

            <p>What happens in the department</p>
            <p>Practitioners refer patients to the clinical physiology department. Patients receive the preliminary results directly after the 
                examination and the final results at their next consultation.
            </p>
            
            <h1>Examinations for complications</h1>
            <p>Electrocardiogram</p>
            <p>Beat to beat: examination of the autonomic nervous system for signs of nerve disorders (the autonomic nervous system is an involuntary 
                system). In this examination changes in the pulse are registered during deep breathing.
            </p>
            
            <p>Orthostatic blood pressure measurement: examination of the autonomic nervous system for signs of nerve disorders. In this examination, 
                changes in blood pressure are measured when the patient moves from a lying to a standing position.
            </p>

            <p>Distal blood pressure measurement: assessment of the blood flow to the feet and toes. This measurement indicates the likelihood of a 
                foot ulcer healing.
            </p>

            <p>GFR examination of renal function, i.e., how efficiently the kidneys clean the blood of the toxins that are constantly being created in the body.</p>

            <h1>Diagnostic examination</h1>
            <p>Glucagon test: examination of how much insulin the pancreas produces.</p>
            <p>Oral Glucose Tolerance Test (OGGT): an examination that can confirm/reject the diagnosis of diabetes.</p>
            <p>Synacthen test: measurement of the adrenal production.</p>
            
            <h1>Clinical biochemistry</h1>
            <p>The work of the department</p>
            <p>The department’s work is very varied and ranges from analysis to teaching and quality control.</p>
            <p>For example, the work comprises:</p>
            <ul>
                <li>taking blood samples in the outpatient clinics and on the outpatient ward</li>
                <li>analysing blood, serum and plasma – hormone and urine analyses are also done</li>
                <li>analyses of material from other hospitals, after prior agreement</li>
                <li>working up and transfer of results to the IT system in the laboratory</li>
                <li>preparing production statistics and running quality assurance on analyses</li>
                <li>testing and data handling of results for research projects</li>
            </ul>

            <p>Taking samples</p>
            <p>Blood glucose and HbA1c are measured prior to almost all consultations in Samatvam; every  year blood samples are taken for 
                routine examination; blood samples are also taken when recommended by a practitioner. Urine samples are taken annually to check 
                the protein content. The patient’s weight is measured at every visit to calculate body mass index (BMI). The care team uses the 
                results of the blood samples and the ECG to determine a diagnosis, draw up a diabetes management plan and prevent further 
                illnesses and/or complications.
            </p>

            <p>Professional profile</p>
            <p>The clinical biochemistry department uses a comprehensive program for analysis that ranges from stix and spectrophotometric 
                methods to immunochemical and chromatographic techniques. Each year the laboratory analyses several thousand patient samples. 
                In line with a mutual agreement, a few samples are sent for analyses at outside reference laboratories
            </p>

            <h1>In Patient Services</h1>
            <p>Diabetes treatment unit</p>
            <p>Patients are admitted for a maximum of five days in the outpatient ward. The ward accommodates 12 patients on a 24-hour basis 
                and 4 on a daytime-only basis. Patients are admitted for both planned and acute hospitalisation.
            </p>
            <p>The staff consists of a duty doctor, nurse, a social health worker and a clinical dietician; an endocrinologist 
                diabetologist,  a medical secretary and a social counsellor are also attached to the ward. The outpatient ward offers 
                experience and training periods for students and trainees.
            </p>
            <p>The most common reasons for hospitalisation:</p>
            <ul>
                <li>recently developed diabetes</li>
                <li>increased acid levels</li>
                <li>start up of night insulin treatment</li>
                <li>foot ulcers</li>
                <li>monitoring and regulation of treatment</li>
                <li>hypos: hypoglycaemia (low blood glucose)</li>
            </ul>

            <p>The aim of hospitalisation:</p>
            <p>to empower the patient to optimise his/her blood glucose levels and thereby improve his/her life quality by preventing development or 
                progression of major diabetic complications. We try to meet this aim by:
            </p>
            <ul>
                <li>making a medical review and giving a status of the patient’s complications</li>
                <li>optimising the patient’s self-monitoring of blood glucose</li>
                <li>motivating the patient to set personal diabetes-management goals</li>
                <li>assessing the patient’s knowledge of diabetes and need for diabetes-related education in order to give him/her greater understanding 
                    of his/her illness
                </li>
                <li>teamworking to create a tailored hospitalisation plan for the patient</li>
                <li>offering education both in a group and on a one-to-one basis in order to strengthen the patient’s management.</li>
            </ul>
            
            <h1>Programme</h1>
            <p>What happens during a typical hospital stay</p>
            <ul>
                <li>the duty doctor establishes the patient’s medical record</li>
                <li> blood samples are taken and the patient is examined</li>
                <li>the patient has an admission consultation with the diabetes specialist counselor where joint goals are set for the hospital stay 
                    and one-to-one education and guidance are planned
                </li>
                <li>the patient has a consultation with the dietician and receives dietary guidance and one-to-one education</li>
                <li>Doctors’ rounds are thrice a week on Tuesdays, Thursdays and Saturdays.</li>
            </ul>

            <p>Group education</p>
            <p>In addition to the standard education programmes, three group education sessions are offered each week. The sessions are based on the participants’ needs and requests</p>
            <p>The sessions offer:</p>
            <ul>
                <li>dietary advice from the dietician</li>
                <li>education from the specialist diabetes nurse</li>
                <li>counselling by a medical social worker</li>
            </ul>

            <p>Theme weeks</p>
            <p>Each year, few  theme weeks are arranged. People with the same diabetes-related problems are offered a hospital stay where they 
                receive education and treatment. Hospital referrals are made by the practitioners in Samatvam. The theme weeks are planned and run by 
                a multidisciplinary team consisting of an endocrinologist diabetologist , a registrar, a diabetes specialist counselor, and a clinical
            </p>

            <h1>Nutritionists</h1>
            <p>Clinical Dieticians</p>
            <p>Samatvam has several clinical dieticians. They work with diabetes teams in the outpatient clinics, the type 2 clinic, the pregnancy 
                clinic, the children and youth clinic, and the diabetes units of affiliated hospitals.
            </p>

            <p>The aim of the work:</p>
            <p>Individual nutrition counselling of persons with diabetes to advise on balancing food, physical exercise and insulin in everyday life 
                and at social events with a view to securing optimal wellbeing. The advice given to people with diabetes must take account of personal 
                preferences and lifestyle.
            </p>

            <p>Work areas</p>
            <p>The major part of the work concerns giving individual nutritional advice to patients during one-to-one consultations at the outpatient clinics.</p>
            <p>A dietician is attached to each diabetes care team. This means that patients can be offered a closely monitored dietary programme for a 
                short period, and/or a consultation with the dietician if needed.
            </p>
            <p>The dieticians also take part in the so-called special programmes, e.g. hypertension or dyslipidaemia, where the team collaborates on the treatment.</p>
            <p>In the type 2 clinic, the dieticians review patients’ food and exercise habits and help set individual dietary management targets, which 
                are followed up by patients attending courses or individual consultations. The dieticians and nurses collaborate to achieve optimal 
                diabetes management. The dieticians participate in multidisciplinary arrangements targeted towards treatment or education.
            </p>

            <h1>Podiatrists</h1>
            <p>The work of the foot clinic</p>
            <p>The podiatrists carry out foot examinations to identify foot conditions in high-risk patients with diabetes. The examination 
                includes advice on wound management and prevention, selection of appropriate footwear and information about the importance of 
                regular foot therapy.
            </p>
            <p>All acute foot ulcers, and leg wounds are treated. Non-infected superficial wounds are debrided and pressure is relieved by cushioning 
                footwear or an aircast boot. If there are signs of infection, a management plan is drawn up in collaboration with a doctor from the 
                outpatient clinic. Treatment may include antibiotics and a check-up visit to the general foot surgeon. Advice is given on footwear 
                to cushion and redistribute pressure, and any orthopaedic footwear the patient may have is checked.
            </p>

            <p>Collaboration</p>
            <p>As well as collaborating with the general foot  surgeon, the podiatrists work in close cooperation with the health professionals 
                in the outpatient clinic and on the outpatient ward.
            </p>
 */}


        </div>
    </>

)
}