import React from 'react'
import PDF1 from '../../image/news-events/The-barefoot-doctor-who-has-cause-a-1000-healthy-smiles.pdf'
import PDF2 from '../../image/news-events/everyone-from-doctor-to-peon-can-diagnose-diabetes-The-New-Indian-Express.pdf'
import PDF3 from '../../image/news-events/MNV-srikanta-iim-l-singhania-award-nomination.pdf'
import PDF4 from '../../image/news-events/KM-MM-SSS-SAMATVAM-PROGRESS-REPORT-2018-2019.pdf'
import PDF5 from '../../image/news-events/Life-100-With-Insulin-And-Jamie.pdf'
import './index.less'

export default function NewsAndEvents() {

    return (
        <>
            <div className='news-events-banner'></div>
            <div className='news-events-wrapper'>
                <p className='main-title'>Latest News and Events:</p>
                <ul>
                    <li><a href={PDF1} target="_blank" rel="noopener noreferrer">The barefoot doctor who has cause a 1000 healthy smiles</a></li>
                    <li><a href={PDF2} target="_blank" rel="noopener noreferrer">BEST Samatvam Here, everyone from doctor to peon, can diagnose diabetes- The New Indian Express</a></li>
                    <li><a href={PDF3} target="_blank" rel="noopener noreferrer">MNV srikanta iim l singhania award nomination</a></li>
                    <li><a href={PDF4} target="_blank" rel="noopener noreferrer">KM MM SSS SAMATVAM PROGRESS REPORT 2018 2019.pdf</a></li>
                    <li><a href={PDF5} target="_blank" rel="noopener noreferrer">Life 100 With Insulin And Jamie</a></li>
                </ul>
            </div>
        </>
    )
}