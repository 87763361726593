import React from 'react'
import HumanBody from '../../image/human-body.png'
import './index.less'

export default function Endocrinology() {

return (
    <>
    {/* About Us Page Banner */}
	<div className='endicronology-banner'></div>
    <div className='endocrinology-wrapper'>
        <p className='main-title'>Hormones and Health</p>
        <p>Endocrinology is the medical discipline dealing with disorders of body’s chemistry, hormones etc. Besides the most common endocrine 
            disorder <span className='highlight'>Diabetes</span>, medical conditions treated by endocrinologists include: <span className='highlight'>Lipids:</span> 
            cholesterol and blood fat disorders; <span className='highlight'>Obesity:</span> weight management; 
            <span className='highlight'>Thyroid:</span> hypothyroidism, hyperthyroidism, goiter, thyroid nodules, thyroid cancer; <span className='highlight'>Bone:</span> osteoporosis / osteopenia, 
            hyperparathyroidism, hypoparathyroidism, vitamin D deficiency; <span className='highlight'>Pituitary:
            </span> pituitary adenoma, galactorrhea, hypopituitarism, diabetes insipidus; <span className='highlight'>
            Adrenal:</span> adrenal adenoma, hyperadrenalism, hypoadrenalism, steroid use; <span className='highlight'>
            Gonads:</span> polycystic ovarian syndrome (PCOS), hirsuitism, hormone related infertility, hypogonadism, 
            Turners Syndrome; <span className='highlight'>Others:</span> hypoglycemia etc.
        </p>
        <p className='title highlight m-tb-10'>Human Body: Endocrine system</p>
        {/* Image will com here */}
        <img className='human-body-img' src={HumanBody} alt='human-body-image' />
        <br />
        <p className='highlight m-tb-10 '>Human problems that will be addressed to provide medical and health care of highest possible quality, include:</p>
        <ul className='m-b80'>
            <li><span className='highlight'>Diabetes Mellitus:</span> Initial visit (IDDM, NIDDM, gestational diabetes, Secondary diabetes); Follow up care ( for both day to day 
                management and long term prevention / management of chronic complications of diabetes, cardiovascular disease, peripheral 
                vascular disease, cerebrovascular disease, nephropathy, retinopathy, neuropathy, diabetic foot).
            </li>
            <li><span className='highlight'>Thyroid Disorders:</span> Hyperthyroidism, hypothyroidism, thyroiditis, thyroid neoplasia.</li>
            <li><span className='highlight'>Reproductive Endocrinology:</span> Male and female infertility, hypogonadism, impotence, menstrual disorders, menopausal disorders, 
                hirsuitism, gynaecomastia, ambiguous genitalia / intersex.
            </li>
            <li><span className='highlight'>Paediatric Endocrinology :</span> Growth and development, short stature, disorders of puberty, endocrine metabolic disorders of infancy childhood and adolescence.</li>
            <li><span className='highlight'>Adrenal Disorders:</span> Addisons disease, Cushings syndrome, hyperaldosteronism, pheochromocytoma, congenital adrenal hyperplasia.</li>
            <li><span className='highlight'>Pituitary and neuroendocrine disorders :</span> Pituitary and hypothalamic tumors, hypopituitarism, diabetes insipidus, SIADH</li>
            <li><span className='highlight'>Obesity:</span> and associated metabolic and medical problems</li>
            <li><span className='highlight'>Dyslipidemia:</span> Atherosclerosis and vascular diseases</li>
            <li><span className='highlight'>Bone and mineral metabolism:</span> Hyperparathyroidism, hypoparathyroidism, hypercalcemia, hypocalemia, rickets and osteomalacia, 
                renal osteodystrophy, fluorosis, kidney stones.
            </li>
            <li>Others: Hypoglycemia and pancreatic endocrine tumors; Endocrine hypertension; Inborn errors of metabolism and other metabolic disorders.</li>
        </ul>
    </div>
    </>
)
}