import React from 'react'
import JananaLogo from '../../image/janana-logo.png'
import SamatvamLogo from '../../image/samatvam_logo.png'
import './index.less'

export default function AboutUs() {

return (
    <>
    {/* About Us Page Banner */}
	<div className='about-us-banner'></div>
    <div className='full-aboutUs-wrapper'>
        <p className='main-title'>About Us</p>
        <p className='sub-title'>Service with Devotion</p>
        <p><b>Samatvam Endocrinology Diabetes </b>is a group of institutions that specialize in treating and managing diabetes and other 
            endocrine metabolic disorders. The central goal is to provide health care of highest possible quality to the highest possible 
            quantity - to foster humane, honest and holistic health care.  The foundation is the harmonious integration of science and 
            spirituality in the context of health care. 
        </p>
        <p>Founded in 1987, <b>Samatvam</b> (= equanimity) has four core areas: health care, education, research and social welfare.</p>
        <p>Currently, we serve through the following health care – medical facilities: </p>
        
        <p className='sub-title'>Samatvam Endocrinology Diabetes Centers</p>
        <ul>
            <li>313, 33 Cross, 17th Main, Jayanagar 4th T Block, Bangalore 560 041. India. Phone: 4111 3308, 2663 7486. 
                24 Hour Helpline: 9448356575. Email: <a href="mailto:samatvam@gmail.com">samatvam@gmail.com</a>
            </li>
            <li>640, I Main, I Stage, Indiranagar, Bangalore 560 038, India. Phone: 91 80 - 25213156, 25257508 24 Hour Helpline: 9449994978. 
                Email: <a href="mailto:sharda.a@gmail.com">sharda.a@gmail.com</a>
            </li>
            <li>82, 36th Main 4th A cross BTM 1st Stage, 1st Phase, Dollar's Scheme, Bangalore - 560068.India. Phone: 7022635742. 
                24 Hour Helpline: 7022635742. Email: <a href="mailto:samatvambtm@gmail.com">samatvambtm@gmail.com</a>
            </li>
        </ul>

        <p><b>Jnana Sanjeevini </b>(= knowledge for health and life) is a dedicated community health care project of <b>Samatvam Trust</b>, 
            initiated in 2003, in JP Nagar, Bangalore.  Its aim is to provide multidisciplinary care for diabetes and related health 
            problems “all under one roof”, irrespective of the socioeconomic status of the beneficiaries. <b>Jnana Sanjeevini </b>is a total 
            health project, which hopes to scientifically combine and integrate both the physical and spiritual dimensions of health, 
            towards human and societal good.
        </p>

        <p className='sub-title'>Jnana Sanjeevini Medical Center and Diabetes Hospitals</p>
        <ul>
            <li>2, 1 A Cross Marenahalli, JP Nagar Phase 2; Bangalore 560078, India; Phone: 91 - 80 - 26493040, 26493060; 
                24 Hour Helpline: 9448350728; Email: <a href="mailto:jsmcindia@gmail.com">jsmcindia@gmail.com</a>
            </li>
        </ul>

        <p className='sub-title'>Medical Directors: Senior Consultants Endocrinology Diabetes</p>
        <ul>
            <li>Dr. S Srikanta. MD (AIIMS), (PhD), FACE (Duke University Medical Center, Joslin Diabetes Center, Harvard Medical School, USA). 
                Email: <a href="mailto:samatvam@gmail.com">samatvam@gmail.com</a>
            </li>
            <li>
            Dr. A Sharda. MD, DNB, MRCP, FACE, FRCP (University California, Henry Ford Hospital, USA; McGill University, Canada; Email: <a href="mailto:sharda.a@gmail.com">sharda.a@gmail.com</a>
            </li>
            <li>Dr. Tejaswini Patil. MD, FEDM, FACE (University Liverpool, Birmingham Heartlands Hospital, Royal Albert Infirmary, UK). 
                Email: <a href="mailto:samatvambtm@gmail.com">samatvambtm@gmail.com</a>
            </li>
        </ul>

        <p className='sub-title'>Diabetes</p>
        <ul className='ul-division'>
            <li>Day Care Unit</li>
            <li>Heart - Kidneys - Eyes - Feet</li>
            <li>Annual Health Review</li>
            <li>Family Education Unit</li>
        </ul>

        <p className='sub-title'>Endocrinology</p>
        <ul className='ul-division'>
            <li>Hormone Laboratory</li>
            <li>Infertility - Reproduction</li>
            <li>Thyroid - Obesity - Lipids</li>
            <li>Growth – Development</li>
        </ul>

        <p className='sub-title'>Diagnostics</p>
        <ul className='ul-division'>
            <li>Laboratory</li>
            <li>ECG</li>
            <li>X-ray</li>
            <li>Ultrasound</li>
            <li>TMT</li>
            <li>ECHO</li>
            <li>PFT</li>
            <li>Dispensary</li>
        </ul>

        <p className='sub-title'>Specialties / Referrals</p>
        <ul className='ul-division'>
            <li>Family Medicine</li>
            <li>Diabetes Endocrinology</li>
            <li>Ophthalmology</li>
            <li>Nephrology</li>
            <li>Cardiology</li>
            <li>Pulmonary Medicine</li>
            <li>Surgery</li>
            <li>Obstetrics Gynecology</li>
            <li>Pediatrics</li>
            <li>Orthopedics</li>
            <li>Rheumatology</li>
            <li>Urology</li>
            <li>Dermatology</li>
            <li>Dentistry</li>
            <li>Psychiatry</li>
            <li>ENT</li>
            <li> Foot Care </li>
            <li>Spiritual Medicine</li>
        </ul>

        <p className='sub-title'>Uniqueness</p>
        <p>What Samatvam aspires to submit to people and society in the years, decades and centuries ahead is:</p>
        <ul>
            <li>utmost honesty</li>
            <li>respect and dignity to the suffering people</li>
            <li>highly merited and devoted students and staff</li>
            <li>health care of highest possible quality to the highest possible quantity: “a temple(s) for healing and learning”, combining:
                <ul>
                    <li>the best of east and west</li>
                    <li>the principles of science and religion</li>
                </ul>
            </li>
        </ul>

        <p className='sub-title'>Inspiration</p>
        <ul>
            <li>“There are diseases of the body, and diseases (ignorance) of the Soul. So keep learning, working and serving.” - Dr Paul Ehrlich</li>
            <li>“From untruth to Truth, from darkness to Light, and from death to Immortality, deliver me."</li>
        </ul>

        <p className='sub-title'>Brhadaranyaka Upanishad</p>
        <ul>
            <li>"Science without religion is lame; Religion without science is blind." - Dr Albert Einstein</li>
            <li>"Science can only prolong life; Religion deepens it." - Dr Yellapragada Subba Row</li>
            <li>"Let every action of mine, be something beautiful for God." - Mother Teresa</li>
            <li>“Be and make.” - Swami Vivekananda</li>
        </ul>

        <p className='sub-title'>Services</p>
        <p>Samatvam serves children and adults with diabetes and other endocrine metabolic disorders from the city of Bangalore, 
            various other parts of Karnataka, neighboring states, and even other parts of India.
            Referred overseas patients also seek Samatvam’s patient care services through annual visits and online consultations. 
            Comprehensive and continuing care (including primary care for desiring patients) and annual health reviews are provided under 
            one roof, with further specialty referrals to eminent doctors and medical centers of Bangalore, as per need. 
            A large number of people with type 1 and type 2 diabetes are treated at our centers. Treatment is both for those with newly 
            diagnosed diabetes as well as for those with late complications. For in-patient hospital and emergency care, we work together 
            with several leading secondary and tertiary care hospitals of Bangalore, where Samatvam doctors and teams serve as 
            Senior Consultants. Fully integrated electronic patient medical records have been used in the clinic since 2003. The goal 
            is to serve not only the patient, but the entire family, covering all socioeconomic segments of our society.
        </p>

        <p className='sub-title'>History</p>
       <div className='d-f history'>
        <div>
        <p><b>Samatvam: Science and Research for Human Welfare Trust</b> is a registered non-profit charitable public trust, envisioned and 
                initiated by a group of medical volunteers in 1987.  [Samatvam = Equanimity; “Samatvam Yoga Uchyate” = Equanimity is Yoga; 
                Bhagavad Gita, 2nd chapter].   Its philosophy is “to try to provide and achieve the best of east and west”, towards honest, 
                humane and holistic health care of highest possible quality to the highest possible quantity. Samatvam was established to 
                provide comprehensive health care for people with diabetes and other endocrine metabolic disorders, with care and 
                compassion (Service with Devotion).   Since its inception, a major focus of Samatvam’s public service activities has been 
                providing treatment to the poor and needy from different parts of Karnataka State, neighboring states, and other parts of India.
                Jnana Sanjeevini (= Knowledge for health and life) is a dedicated community health care project of Samatvam Trust, 
                initiated in 2003, in JP Nagar, Bangalore.  Its aim is to provide multidisciplinary care for diabetes and related health 
                problems “all under one roof”, irrespective of the socioeconomic status of the beneficiaries [Project DISHA and Project DOSTI].
            </p>
            <p className='m-tb-10'>The special strengths of <b>Samatvam</b> include merited faculty and staff, ongoing international collaborations and enrichment, 
                and most importantly, sincerity and dedication in public service and human good <b>[“Service with devotion”]</b>.
            </p>
        </div>
            <img src={JananaLogo} className='about-us-logo' />
       </div>

        <p className='sub-title'>Logo</p>
        <div className='d-f'>
            <img src={SamatvamLogo} className='samatvam-logo' />
            <p className='m-b40'>The founding philosophy of Samatvam (= equanimity, Samatvam Yoga Uchyate, sanskrit for Equanimity is Yoga, Bhagavad Gita, 2nd chapter) 
                is <b>harmonious integration of science and spirituality in the context of health care</b>.  Thus, in 1986, its initial logo was a 
                simple “circle with a horizontal diameter”, representing the 2 balanced facets, science and spirituality. In 1990, the logo 
                received an artistic and scientific rendition (Ms Wasima Kausar, Dr Pankaj Shah), when it became a “Konark wheel inside a 
                recombinant DNA bacterial plasmid”, once again reflecting the fusion of spirituality and science.  In 1994, the Konark wheel 
                was “humanized” (Mr KR Bilimoria), with the 8 spokes of the wheel being artistically simplified and transformed into 8 human beings 
                holding hands (4 persons in blue representing the patients and 4 persons in red representing the medical team), with all their 
                heads (and hearts) in unison at the central hub. [Current logo: “humanized Konark wheel inside a recombinant DNA bacterial plasmid]. 
                This evolution emphasizes the importance of the close networking, friendship and compassionate commitment between the health care 
                recipients and providers, all with full faith in God <b>(We Plan, He Steers;  We Pray, He Wills; We Care, He Heals.)</b>
            </p>
        </div>
    </div>
    </>
    
)
}