import React from 'react'
import { Modal } from 'antd';
import {Carousel} from '../../components/Base/Layout.js';
import GalleryWrap1 from '../../image/gallery/gallery-wrap1.jpeg'
import GalleryWrap2 from '../../image/gallery/gallery-wrap2.jpeg'
import GalleryWrap3 from '../../image/gallery/gallery-wrap3.jpeg'
import GalleryWrap4 from '../../image/gallery/gallery-wrap4.jpeg'
import Slide31 from '../../image/gallery/Samatvam-International-Eminence/Slide31.JPG'
import Slide32 from '../../image/gallery/Samatvam-International-Eminence/Slide32.JPG'
import Slide33 from '../../image/gallery/Samatvam-International-Eminence/Slide33.JPG'
import Slide34 from '../../image/gallery/Samatvam-International-Eminence/Slide34.JPG'
import Slide35 from '../../image/gallery/Samatvam-International-Eminence/Slide35.JPG'
import Slide36 from '../../image/gallery/Samatvam-International-Eminence/Slide36.JPG'
import Slide37 from '../../image/gallery/Samatvam-International-Eminence/Slide37.JPG'
import Slide38 from '../../image/gallery/Samatvam-International-Eminence/Slide38.JPG'
import Slide39 from '../../image/gallery/Samatvam-International-Eminence/Slide39.JPG'
import Slide40 from '../../image/gallery/Samatvam-International-Eminence/Slide40.JPG'
import Slide41 from '../../image/gallery/Samatvam-International-Eminence/Slide41.JPG'
import Slide42 from '../../image/gallery/Samatvam-International-Eminence/Slide42.JPG'
import Slide50 from '../../image/gallery/Samatvam-International-Eminence/Slide50.JPG'
// 2nd Gallery
import Slide52 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide52.JPG'
import Slide53 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide53.JPG'
import Slide54 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide54.JPG'
import Slide55 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide55.JPG'
import Slide56 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide56.JPG'
import Slide57 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide57.JPG'
import Slide58 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide58.JPG'
import Slide59 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide59.JPG'
import Slide60 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide60.JPG'
import Slide61 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide61.JPG'
import Slide62 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide62.JPG'
import Slide63 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide63.JPG'
import Slide64 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide64.JPG'
import Slide65 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide65.JPG'
import Slide66 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide66.JPG'
import Slide67 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide67.JPG'
import Slide68 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide68.JPG'
import Slide69 from '../../image/gallery/Samatvam-Camp-Man-Mohan/Slide69.JPG'
// 3rd Gallery
import Slide20 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide20.JPG'
import Slide21 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide21.JPG'
import Slide22 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide22.JPG'
import Slide23 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide23.JPG'
import Slide26 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide26.JPG'
import Slide27 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide27.JPG'
import Slide32B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide32.JPG'
import Slide35B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide35.JPG'
import Slide36B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide36.JPG'
import Slide37B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide37.JPG'
import Slide39B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide39.JPG'
import Slide41B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide41.JPG'
import Slide46 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide46.JPG'
import Slide47 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide47.JPG'
import Slide48 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-1/Slide48.JPG'
// 4th Gallery
import Slide50B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide50.JPG'
import Slide64B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide64.JPG'
import Slide65B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide65.JPG'
import Slide66B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide66.JPG'
import Slide68B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide68.JPG'
import Slide69B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide69.JPG'
import Slide70B from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide70.JPG'
import Slide74 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide74.JPG'
import Slide83 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide83.JPG'
import Slide95 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide95.JPG'
import Slide96 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide96.JPG'
import Slide97 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide97.JPG'
import Slide99 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide99.JPG'
import Slide100 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide100.JPG'
import Slide101 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide101.JPG'
import Slide102 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide102.JPG'
import Slide103 from '../../image/gallery/Dr-S-Srikanta-Physician-Scientist-Social-Worker-2/Slide103.jpg'

import { LeftOutlined, RightOutlined} from '@ant-design/icons';
import './index.less'

class PhotosAndGallery extends React.Component {
    state = { visibleModalOne: false, visibleModalTwo: false, visibleModalThree: false, visibleModalFour: false };
    showOneModal = () => {
	    this.setState({visibleModalOne: true})
    };

    showTwoModal = () => {
	    this.setState({visibleModalTwo: true})
    };

    showThirdModal = () => {
        this.setState({visibleModalThree: true})
    }

    showFourModal = () => {
        this.setState({visibleModalFour: true})
    }
      
	  handleGalleryOneOk = e => {
		this.setState({
            visibleModalOne: false,
		});
      };
      
      handleGalleryTwoOk = e => {
		this.setState({
            visibleModalTwo: false,
		});
      };
      
      handleGalleryThreeOk = e => {
		this.setState({
            visibleModalThree: false,
		});
      };
      
      handleGalleryFourOk = e => {
		this.setState({
            visibleModalFour: false,
		});
      };
      
    //   Carousel 
    constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.carousel = React.createRef();
	  }
	  next() {
		this.carousel.next();
	  }
	  previous() {
		this.carousel.prev();
	  }

    render() {
    return (
        <>
            {/* <div className='photos-banner'></div> */}
            <div className='photos-gallery-wrapper'>
                <p className='main-title'>Latest Videos:</p>
                <div className='videos-wrapper'>
                    <iframe width="500" height="345" src="https://www.youtube.com/embed/LedPU0LmQ9s"></iframe>
                    <iframe width="500" height="345" src="https://www.youtube.com/embed/1lOx4Bfb7yc"></iframe>
                    <iframe width="500" height="345" src="https://www.youtube.com/embed/9MHJ0oK5Vps"></iframe>
                    <iframe width="500" height="345" src="https://www.youtube.com/embed/LUHcVREzZPM"></iframe>
                </div>

                <p className='main-title'>Latest Photos:</p>
                <div className='videos-wrapper m-b80'>
                    <div className='img-wrap'>
                        <img src={GalleryWrap1} className='image' onClick={this.showOneModal}/>
                    </div>
                    <div className='img-wrap'>
                        <img src={GalleryWrap2} className='image' onClick={this.showTwoModal} />
                    </div>
                    <div className='img-wrap'>
                        <img src={GalleryWrap3} className='image' onClick={this.showThirdModal}/>
                    </div>
                    <div className='img-wrap'>
                        <img src={GalleryWrap4} className='image' onClick={this.showFourModal}/>
                    </div>    
                </div>

                <Modal classname='gallery-modal' visible={this.state.visibleModalOne} onOk={this.handleGalleryOneOk} onCancel={this.handleGalleryOneOk}>
                    <LeftOutlined onClick={this.previous} className='left-carousel'/>
                    <Carousel ref={node => (this.carousel = node)} autoplay>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide31} alt='Slide31' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide32} alt='Slide32' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide33} alt='Slide33' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide34} alt='Slide34' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide35} alt='Slide35' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide36} alt='Slide36' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide37} alt='Slide37' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide38} alt='Slide38' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide39} alt='Slide39' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide40} alt='Slide40' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide41} alt='Slide41' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide42} alt='Slide42' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide50} alt='Slide50' />
                        </div>
                    </Carousel>
                    <RightOutlined onClick={this.next} className='right-carousel' />
                </Modal>

                {/* Modal 2 */}
                <Modal classname='gallery-modal' visible={this.state.visibleModalTwo} onOk={this.handleGalleryTwoOk} onCancel={this.handleGalleryTwoOk}>
                    <LeftOutlined onClick={this.previous} className='left-carousel'/>
                    <Carousel ref={node => (this.carousel = node)} autoplay>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide52} alt='Slide52' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide53} alt='Slide53' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide54} alt='Slide54' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide55} alt='Slide55' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide56} alt='Slide56' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide57} alt='Slide57' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide58} alt='Slide58' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide59} alt='Slide59' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide60} alt='Slide60' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide61} alt='Slide61' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide62} alt='Slide62' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide63} alt='Slide63' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide64} alt='Slide64' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide65} alt='Slide65' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide66} alt='Slide66' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide67} alt='Slide67' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide68} alt='Slide68' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide69} alt='Slide69' />
                        </div>
                    </Carousel>
                    <RightOutlined onClick={this.next} className='right-carousel' />
                </Modal>

                {/* Modal 3 */}
                <Modal classname='gallery-modal' visible={this.state.visibleModalThree} onOk={this.handleGalleryThreeOk} onCancel={this.handleGalleryThreeOk}>
                    <LeftOutlined onClick={this.previous} className='left-carousel'/>
                       <Carousel ref={node => (this.carousel = node)} autoplay>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide20} alt='Slide20' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide21} alt='Slide21' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide22} alt='Slide22' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide23} alt='Slide23' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide26} alt='Slide26' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide27} alt='Slide27' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide32B} alt='Slide32' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide35B} alt='Slide35' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide36B} alt='Slide36' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide37B} alt='Slide37' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide39B} alt='Slide39' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide41B} alt='Slide41' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide46} alt='Slide46' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide47} alt='Slide47' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide48} alt='Slide48' />
                        </div>
                    </Carousel>
                    <RightOutlined onClick={this.next} className='right-carousel' /> 
                </Modal>

                {/* Modal 4 */}
                <Modal classname='gallery-modal' visible={this.state.visibleModalFour} onOk={this.handleGalleryFourOk} onCancel={this.handleGalleryFourOk}>
                    <LeftOutlined onClick={this.previous} className='left-carousel'/>
                    <Carousel ref={node => (this.carousel = node)} autoplay>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide50B} alt='Slide50' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide64B} alt='Slide64' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide65B} alt='Slide65' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide66B} alt='Slide66' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide68B} alt='Slide68' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide69B} alt='Slide69' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide70B} alt='Slide70' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide74} alt='Slide74' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide83} alt='Slide83' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide95} alt='Slide95' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide96} alt='Slide96' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide97} alt='Slide97' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide99} alt='Slide99' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide100} alt='Slide100' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide101} alt='Slide101' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide102} alt='Slide102' />
                        </div>
                        <div className='gallery-img-wrapper'>
                            <img src={Slide103} alt='Slide103' />
                        </div>
                    </Carousel>
                    <RightOutlined onClick={this.next} className='right-carousel' /> 
                </Modal>
            </div>
        </>

    )}
}
export default PhotosAndGallery