import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Carousel from 'antd/lib/carousel';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

const {Header, Content, Sider, Footer} = Layout;

/**
 * Might add other layout specific components such as Header, Sider, Content in future if required.
 * Ref: https://ant.design/components/layout/
 */

export {Row, Col, Header, Content, Sider, Layout, Card, Skeleton, Footer, Menu, Carousel, Form, Input, Button};