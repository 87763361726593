import React from 'react'
import './index.less'

export default function HealthProfessional() {
    return (
        <>
            <div className='health-professional-banner'></div>
            <div className='health-professional-wrapper'>
                <p className='main-title'>Igniting the Minds – Torch of Life</p>
                <p className='sub-title'>Continuing Medical Education:</p>
                <p className='m-b20'>Project DEEPA is directed towards Diabetes physicians, counsellors and other health professionals.   This fosters the much needed Diabetes Health Care Manpower Development and Capacity Building, through local, national and international:  Scholarships - Observerships - Internships and Fellowships; Continuing medical education and training</p>
                
                <ul className='m-b80'>
                    <li><b>Physicians: [National, International]</b></li>
                    <li><b>Public Health Foundation of India PHFI:</b></li>
                    <ul>
                        <li>Primary Care – Specialist Doctors trained</li>
                        <li>Certificate courses</li>
                        <li>Diabetes – Basic: 210</li>
                        <li>Diabetes – Pregnancy: 108</li>
                        <li>Diabetes – Cardiology: 123</li>
                        <li>Thyroid: 125</li>
                        <li>India’s / World’s: Highest enrolment</li>
                        <li>Health Care Manpower Capacity Building</li>
                    </ul>

                    <li><b>Physicians: [National, International]</b></li>
                    <li><b>India International Knowledge Forum – IIKF</b></li>
                    <ul>
                        <li>First: 2015;  Second: 2015 [2 days]</li>
                        <li>Prof MMS Ahuja Sri GC Surana Educational Endowment</li>
                        <li>8 International Faculty</li>
                        <li>16 National Faculty; 22 Chairpersons</li>
                        <li>700 Delegates [Physicians, Pediatricians, Gynecologists, Endocrinologists]</li>
                    </ul>

                </ul>
            </div>
        </>
    )
}