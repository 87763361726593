import React from 'react'
import EducationDeepa from '../../image/education/education-deepa.png'
import Education1 from '../../image/education/education-1.png'
import Education2 from '../../image/education/education-2.png'
import Education3 from '../../image/education/education-3.png'
import Education4 from '../../image/education/education-4.png'
import Education5 from '../../image/education/education-5.png'
import Education6 from '../../image/education/education-6.png'
import Education7 from '../../image/education/education-7.png'
import Education8 from '../../image/education/education-8.png'
import Education9 from '../../image/education/education-9.png'
import Education10 from '../../image/education/education-10.png'
import Education11 from '../../image/education/education-11.png'
import Education12 from '../../image/education/education-12.png'
import './index.less'

export default function Education() {

    return (
        <>
            <div className='education-banner'>
            {/* banner */}
            </div>
            <div className='full-education-wrapper'>
                <p className='main-title'>Knowledge is Power</p>
                <ul>
                    <li><b>Project DEEPA</b> is directed towards Diabetes physicians, counselors 
                        and other health professionals.  This fosters the much needed Diabetes Health Care Manpower 
                        Development and Capacity Building, through local, national and international Scholarships - 
                        Observerships - Internships and Fellowships, and Continuing Medical Education and training.
                        <div className='education-deepa'>
                            <img src={EducationDeepa} />
                        </div>
                    </li>
                    <li><b>Igniting the Minds – Samatvam Alumni:</b> Samatvam Alumni: ie. Medical and other students, 
                        physician residents, fellows and consultants, nurses, counselors, social workers and scientists, 
                        all inspired, trained and nurtured by Samatvam – Jnana Sanjeevini, are currently serving people 
                        and communities across the world, in various specialties and capacities.
                    </li>
                    <li>
                        <b>Torch of Life – Diabetes health care manpower capacity building :</b>  In collaboration with 
                        Public Health Foundation of India, Samatvam has trained the record highest number [for the 
                        country] of primary care and specialist doctors, to deliver higher standards of diabetes care 
                        for the people and families served by them.  The philosophy of Service with devotion, scientific 
                        spirit and passionate and compassionate medical care have been intensively infused.
                    </li>
                </ul>

                <p className='sub-title'>Education: Medical Education – Jnana Sanjeevini</p>
                <ul className='wrap-list'>
                    <li>In House Medical Education Programs</li>
                    <li>Seminars and Workshops</li>
                    <li>Local, National and International CMEs</li>
                    <li>National and International Observerships</li>
                    <li>Awards</li>
                </ul>

                <p className='sub-title'>Medical Education – Jnana Sanjeevini</p>
                <ul className='wrap-list'>
                    <li><b>Clinic Table Rounds:</b></li>
                    <ul>
                        <li className='some-full-list'>Every Day – Every Patient</li>
                    </ul>
                    <li><b>Sanjeevini Jnana Yagna:</b></li>
                    <ul>
                        <li className='some-full-list'>Every Tuesday – 6 hours</li>
                    </ul>
                    <li><b>Teleeducation - Webinars:</b></li>
                    <ul>
                        <li className='some-full-list'>Sundays, Tuesdays</li>
                    </ul>
                </ul>

                <div className='education-gallery-wrapper m-b80'>
                    <div className='education-gallery'>
                        <img src={Education1} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education2} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education3} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education4} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education5} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education6} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education7} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education8} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education9} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education10} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education11} />
                    </div>

                    <div className='education-gallery'>
                        <img src={Education12} />
                    </div>
                </div>
            </div>
        </>
    )
}