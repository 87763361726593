import React from 'react'
// import AboutUsImg from '../../image/about-us.svg'
import './index.less'

export default function Specialities() {

    return (
        <>
        <div className='specialities-bg'></div>
        <div className='full-specialities-wrapper'>
            <p className='main-title'>Advanced Medical Care</p>
            <p className='sub-title'>Specialties / Referrals</p>

            <ul className='m-b80'>
                <li>Family Medicine </li>
                <li>Diabetes Endocrinology </li>
                <li>Ophthalmology </li>
                <li>Nephrology </li>
                <li>Cardiology </li>
                <li>Pulmonary Medicine </li>
                <li>Surgery </li>
                <li>Obstetrics Gynecology </li>
                <li>Pediatrics </li>
                <li>Orthopedics </li>
                <li>Rheumatology </li>
                <li>Urology </li>
                <li>Dermatology </li>
                <li>Dentistry </li>
                <li>Psychiatry  </li>
                <li>ENT </li>
                <li>Foot Care </li>
                <li>Spiritual Medicine</li>
            </ul>
        </div>
        </>
    )
}