import React from 'react'
import {
	Link
  } from "react-router-dom";
import HeaderLayout from '../Header'
import './index.less'
import Logo from '../../image/samatvam_logo.png'
import StopDiabetes from '../../image/stop-diabetes.png'
import JananaLogo from '../../image/janana-logo.png'
import { FacebookOutlined, YoutubeOutlined, InstagramOutlined, MailOutlined } from '@ant-design/icons';

export default function AppLayout(props) {
	return (
		<>
		<div className='logo-line d-f jc-sb'>
			<div className='logo'>
				<img src={Logo} alt='Samatvam Logo' />
			</div>
				
			<div className='stop-diabetes-logo'>
				<img src={StopDiabetes} alt='Stop Diabetes Logo' />
			</div>

			<div className='logo'>
				<img src={JananaLogo} alt='Janana Logo' />
			</div>
		</div>
		<HeaderLayout />
		{props.children}
		{/* Footer */}
		<section className='footer-wrapper'>
				<p className='copyright'>© Copyright 2020 All Rights Reserved Samatvam.org</p>
			</section>
		</>
	)
}