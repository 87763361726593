import React from 'react'
import './index.less'

export default function InternationalPatients() {
    return (
        <>
            <div className='internationa-patients-banner'></div>
            <div className='international-patients-wrapper'>
                <p className='main-title'>Global Endocrinology Diabetes Advanced Counselling and Care</p>
                <p className='m-b20'>At Samatvam Jnana Sanjeevini, it has been our endeavour to redefine the future of healthcare through 
                    specializing in Endocrinology Diabetes care and advanced diagnosis and treatment. About 18 nationally 
                    and internationally trained and accomplished specialists work with Samatvam Jnana Sanjeevini - 
                    The specialists in Endocrinology Diabetes care. We have engaged in a constant endeavour to mould the 
                    future of healthcare in India by designing, building and managing healthcare centres with a steadfast 
                    vision of bringing core clinical services under one roof. Our intent is to help patients achieve a 
                    longer and better life. With a network of 5 Endocrinology Diabetes centres across Bangalore, India, 
                    Samatvam Jnana Sanjeevini makes advanced Endocrinology Diabetes care accessible to thousands of people.
                </p>

                <p className='m-b20'>Samatvam Jnana Sanjeevini is striving to transform the healthcare environment of the country in its 
                    core speciality, ensuring that it brings together high quality, cutting edge expertise and advanced 
                    knowledge. At Samatvam Jnana Sanjeevini we offer all modalities of Endocrinology Diabetes diagnosis 
                    and treatment with the most advanced state-of-art technologies under a single roof.
                </p>

                <p className='m-b20'>Telemedicine services and Electronic Medical Records are at the heart of superior clinical care at 
                    Samatvam Jnana Sanjeevini – Counselling and care for people from all parts of the world – with the 
                    inherent limitations of Telemedicine.
                </p>

                <p className='m-b80'>For further information, please contact Samatvam Jnana Sanjeevini</p>
            </div>
        </>
    )
}