import React from 'react'
import Srikanta from '../../image/srikanta.png'
import './index.less'

export default function SSriKanta() {
    return (
        <>
             <div className='special-doctor-wrapper m-b80'>
                <div className='d-f jc-sb special-doc'>
                    <div className='p-r90'>
                    <p className='main-title'>Dr S Srikanta</p>
                    <p className='m-b10'><b>Designation:</b> Senior Consultant Endocrinology Diabetes Medicine, Faculty and “Gardner”; Former Medical Director and Founder – 
                        Managing Trustee
                    </p>
                    <p className='m-b10'><b>Specialties:</b> Endocrinology, Diabetes, Medicine and Preventive Health Care</p>
                    <p className='m-b10'><b>Email:</b> samatvam@gmail.com</p>
                    <p><b>Clinical Interests:</b> Diabetes – Power of Prevention;  Clinical Endocrinology in Primary Care; Preventive Cardiology; Medical Pedagogy; Health Care 
                    Information Technology; Spiritual Medicine</p>
                    </div>
                    <div className='doc-image'>
                        <img src={Srikanta} alt='Srikanta Image' />
                    </div>
                </div>

                <p className='title m-t20'>Education</p>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1971 - 1978</p>
                    <p className='m-l40'>
                        <b>Bachelor of Medicine and Bachelor of Surgery (MBBS – MD)</b>
                        <br />
                        <span className='f-sz14'>Bangalore Medical College, Bangalore University, India</span>
                    </p>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1978 - 1981</p>
                    <p className='m-l40'>
                        <b>Doctor of Medicine, General Medicine - Post Graduate (MD)</b>
                        <br />
                        <span className='f-sz14'>All India Institute of Medical Sciences (AIIMS), New Delhi, India</span>
                    </p>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1981 - 1983</p>
                    <p className='m-l40'>
                        <b>Fellow, Endocrinology, Diabetes and Metabolism</b>
                        <br />
                        <span className='f-sz14'>Duke University, Durham, NC; Harvard Medical School, Boston, MA, USA</span>
                    </p>
                </div>

                <p className='title m-t20'>Distinctions</p>
                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1993</p>
                    <p>
                        <b>Visiting Professor</b>
                        <br />
                        University of Minnesota, Minneapolis, MN, USA (Pancreas and islet transplantation program)- Department of Surgery
                    </p>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>2003</p>
                    <p>
                        <b>Visiting Clinician</b>
                        <br />
                        Mayo Clinic, Rochester, USA
                    </p>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>2004</p>
                    <p>
                        <b>Fellow</b>
                        <br />
                        American College of Endocrinology [FACE ]
                    </p>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>2009</p>
                    <p>
                        <b>Visiting Professor</b>
                        <br />
                        University of Michigan Medical School, Ann Arbor, Michigan, USA, Department of Endocrinology Diabetes
                    </p>
                </div>

                <p className='title m-t20'>Work Experience</p>
                
                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1983 - 1987</p>
                    <div className='exp-desc'>
                        <p>Faculty, Physician and Investigator, Endocrinology, Diabetes and Metabolism</p>
                        <p>Department of Medicine, Division of Diabetes Endocrinology and Metabolism, and Section of Immunology and Clinical Physiology
                            Harvard Medical School, Brigham and Women's Hospital, Joslin Diabetes Center, USA.
                        </p>
                    </div>
                </div>
                
                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1987 - 1992</p>
                    <div className='exp-desc'>
                        <p>Scientific Officer and Assistant Director, Endocrinology, Diabetes and Metabolism</p>
                        <p>All India Institute of Medical Sciences (AIIMS), Indian Council of Medical research (ICMR) and Council of Scientific and Industrial 
                        Research, New Delhi, India.
                    </p>
                    </div>
                </div>
                
                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1992 - 1993</p>
                    <div className='exp-desc'>
                        <p>Professor and Head, Endocrinology, Diabetes and Metabolism and Chief, Center for Endocrine Sciences</p>
                        <p>Sanjay Gandhi Post Graduate Institute of Medical Sciences, Lucknow, India.</p>
                    </div>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>2000 - 2004</p>
                    <div className='exp-desc'>
                        <p>Professor, Dept of Endocrinology Diabetes, Division of Life Sciences</p>
                        <p>VYOMA: Vivekananda Yoga Mahavidyapeeta (Deemed University), Bangalore, India.</p>
                    </div>
                </div>

                <div className='d-f m-b10'>                
                    <p className='m-r40 year'>1994 - Current</p>
                    <div className='exp-desc'>
                        <p>Senior Consultant, Endocrinology Diabetes Medicine and Medical Director</p>
                        <p>Samatvam Endocrinology Diabetes Center, Jnana Sanjeevini Medical Center and Diabetes Hospital, Bangalore, India</p>
                    </div>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1993 - 2014</p>
                    <div className='exp-desc'>
                        <p>Founder and Managing Trustee</p>
                        <p>Samatvam: Science and Research for Human Welfare Trust, Bangalore, India</p>
                    </div>
                </div>

                <div className='d-f m-b10'>
                    <p className='m-r40 year'>1994 - Current</p>
                    <div className='exp-desc'>
                        <p>Senior Consultant, Endocrinology Diabetes Medicine</p>
                        <p>Shanthi Hospital and Research Center, Shanthi Nursing Home, Sagar Hospital, Sri Krishna Sevashrama Hospital, Rangadore Memorial 
                            Vikram Hospital (Previously also: Wockhardt Hospital, Mahaveer Jain Hospital, Agadi Hospital, Mallya Hospital etc), Bangalore, India
                        </p>
                    </div>
                </div>
                
                <div className='d-f m-b10'>
                    <p  className='m-r40 year'>1994 - Current</p>
                    <div className='exp-desc'>
                        <p>Honorary Consultant, Endocrinology Diabetes Metabolism</p>
                        <p>National Institute of Mental Health and Neurological Sciences (NIMHANS), Bangalore, India</p>
                    </div>
                </div>
            </div>
        </>
    )
}