import React from 'react'
import CharityDisha from '../../image/charity/charity-disha.png'
import CharityDosti from '../../image/charity/charity-dosti.png'
import Charity1 from '../../image/charity/charity-1.png'
import Charity2 from '../../image/charity/charity-2.png'
import Charity3 from '../../image/charity/charity-3.png'
import CharityFooter from '../../image/charity/charity-footer.png'
import './index.less'

export default function Charity() {
    return (
        <>
            <div className='charity-banner'></div>
            <div className='charity-wrapper'>
                <p className='main-title'>Service to Man is Service to God</p>
                <p className='m-b20'><b>“No one will leave Jnana Sanjeevini uncared and unprovided, irrespective of their 
                    socioeconomic status.”</b> This was the premise for the inception of Samatvam in 1987. Till today, 
                    with the philanthropy and magnanimity of few Good Samaritans [individuals and institutions], and 
                    the “Service with Devotion” of our volunteers, our institution has been able to keep up with this 
                    promise, in its own little way.
                </p>

                <p className='m-b20'>Through the charity Projects DISHA and DOSTI, more than 3000 children with Type 1 
                    diabetes, and 30,000 adults with type 2 diabetes, thus far, have been the beneficiaries of FREE medical 
                    care and counseling, medicines, diagnostic services, education – employment and other forms of social 
                    support etc. Besides the above FREE services, social engineering and cost cross subsidy, have helped us 
                    to reach out a much larger segment of our society at the bottom of the socioeconomic pyramid.
                </p>

                <p className='title'>DISHA DOSTI</p>
                <p className='m-b20'>Samatvam Free Diabetes Clinic for the Poor</p>
                <div className='d-f jc-sb'>
                    <div className='charity-two-section'>
                        <div>
                            <img src={CharityDisha} />
                        </div>
                        <p className='m-t40'><b>DISHA:</b> Providing FREE life saving insulin, comprehensive medical care and social support 
                            for all poor and needy type 1 (insulin dependent) diabetes children in the community, 
                            to ensure the basic survival and optimum health of these unfortunate children, afflicted 
                            by a life long killer disease (but well manageable with proper medical and social support).
                        </p>
                    </div>

                    <div className='charity-two-section'>
                        <div>
                            <img src={CharityDosti} />
                        </div>
                        <p className='m-t40'><b>DOSTI:</b>  Providing FREE life saving diabetes, blood pressure, lipids
                            and related medicines and comprehensive medical care and social support for all poor and 
                            needy type 2 (non-insulin dependent) diabetes adults in the community, to ensure the basic 
                            survival and optimum health of these unfortunate people, afflicted by a lifelong, 
                            debilitating killer disease (but well manageable with proper medical and social support).
                        </p>
                    </div>
                </div>

                <p className='m-b10'><b>Cost of “Basic / Minimum” Type 1 Diabetes Care per child per year in India (approx.) Out Patient**</b></p>

                <table className='charity-table m-tb-20'>
                    <tr className='main-color'>
                        <th>Item</th>
                        <th>Cost Rupees</th>
                        <th>Notes</th>
                    </tr>
                    <tr>
                        <td>Insulin</td>
                        <td>6000</td>
                        <td>Human insulin @ 40 units per day</td>
                    </tr>
                    <tr>
                        <td>Insulin Syringes</td>
                        <td>1500</td>
                        <td>Average 200 Syringes per year</td>
                    </tr>
                    <tr>
                        <td>Diagnostic Tests</td>
                        <td>2500</td>
                        <td>HbA1C, S Creatinine, Urine Microalbumin, CBC, TSH, Eye- Fundus</td>
                    </tr>
                    <tr>
                        <td>Transportation</td>
                        <td>2000</td>
                        <td>1 Specialist, 6 general doctors’ visits</td>
                    </tr>
                    <tr className='main-color'>
                        <td>Total</td>
                        <td>20000</td>
                        <td></td>
                    </tr>
                </table>

                <p className='m-b10'><b>Cost of “Basic / Minimum” Type 2 Diabetes Care per adult per year in India (approx.) Out patient**</b></p>
                <table className='charity-table m-tb-20'>
                    <tr className='main-color'>
                        <th>Item</th>
                        <th>Cost Rupees</th>
                        <th>Notes</th>
                    </tr>
                    <tr>
                        <td>Medicines</td>
                        <td>6000</td>
                        <td>Diabetes, blood pressure, lipids and related medicines</td>
                    </tr>
                    <tr>
                        <td>Diagnostic Tests</td>
                        <td>3000</td>
                        <td>HbA1C, S Creatinine, Urine Microalbumin, CBC,  Eye- Fundus, Radiology, Cardiology etc</td>
                    </tr>
                    <tr>
                        <td>Transportation</td>
                        <td>1000</td>
                        <td>1 Specialist, 3 general doctors’ visits</td>
                    </tr>
                    <tr className='main-color'>
                        <td>Transportation</td>
                        <td>10000</td>
                        <td></td>
                    </tr>
                </table>

                <p><b>** In the event of need for hospitalisation the expense could well exceed Rs 16000 per hospitalisation (Median cost</b></p>

                <p><b>Donations</b> of any kind (material and might) are welcome and gratefully acknowledged (including naming 
                    your sponsorship / facility). Donations made to <b>"SAMATVAM :Science and Research for Human Welfare 
                    Trust”</b> qualify for exemption under the section 80G of the Income Tax Act – Govt of India. [<b>Trustees - 
                    Contact persons</b>: Ms Padmaja Srinivas, 91-9845445660; <a href="mailto:psrinivasdiabetes@gmail.com">psrinivasdiabetes@gmail.com</a>; 
                    Dr Bhanudev Bhatt, 91-9448391060, <a href="mailto:bbhatt.samatvam@gmail.com">bbhatt.samatvam@gmail.com</a>; Mr Harsha Ananthaswamy, 
                    91-9986304719, <a href="mailto:aharshadiabetes@gmail.com">aharshadiabetes@gmail.com</a>]. 
                    C /O: Jnana Sanjeevini; 2, 1st A Cross, Marenahalli, J P Nagar, Phase 2, Bangalore 560078, India.
                </p>

                <p className='m-tb-10'><b>Respects and Pranams</b></p>
                <p className='m-b20'><b>Patrons - Trustees - Staff - Volunteers - Team Samatvam</b></p>

                <p className='donation-color m-b20'><b>Donations through Cheque / Drafts:</b></p>
                <table className='charity-table'>
                    <tr>
                        <th>In Favour of</th>
                        <th>Samatvam Science and Research for Human Welfare Trust</th>
                    </tr>
                    <tr>
                        <td>Payable at</td>
                        <td>Bangalore, India</td>
                    </tr>
                </table>

                <p className='donation-color m-tb-20'><b>Donations through Online Transfer’s via NEFT/RTGS, bank details are as below:</b></p>
                <table className='charity-table'>
                    <tr>
                        <th>Name</th>
                        <th>Samatvam Science and Research for Human Welfare Trust.</th>
                    </tr>
                    <tr>
                        <td>Account Number</td>
                        <td>034601000010752</td>
                    </tr>
                    <tr>
                        <td>Bank/Branch</td>
                        <td>Indian Overseas Bank, Jayanagar 5th Block Branch, Bangalore, India</td>
                    </tr>
                    <tr>
                        <td>IFSC</td>
                        <td>IOBA000034</td>
                    </tr>
                </table>

                <p className='donation-color m-tb-20'><b>All Foreign/International  Donations to made to designated 
                    FCRA Bank account only as below:</b>
                </p>
                <table className='charity-table'>
                    <tr>
                        <th>Name</th>
                        <th>Samatvam Science and Research for Human Welfare Trust.</th>
                    </tr>
                    <tr>
                        <td>Account Number</td>
                        <td>O67701000016848</td>
                    </tr>
                    <tr>
                        <td>Bank/Branch</td>
                        <td>Indian Overseas Bank/ East End Main Road Branch.42/166, East End Main Road,  9th  Block, Jayanagar,
                            Bangalore -560069, Karnataka, India.
                        </td>
                    </tr>
                    <tr>
                        <td>IFSC</td>
                        <td>IOBAINBB011</td>
                    </tr>
                </table>

                <ul className='wrap-list m-t20'>
                    <li>Approval U/s 80G from Director of Income Tax (Exemptions) – No: DIT(E/80G/(R/3/AAATS5649B/ ITO(E)-3/2012-13, 
                        Dated: 09/04/2012. Trust PAN: AAATS5649B.
                    </li>
                    <li>Registration Under Foreign Contribution (Regulation) Act (FCRA); Registration Number: 094421055</li>
                </ul>

                <div className='charity-gallery m-b40'>
                    <div>
                        <img src={Charity1} />
                    </div>

                    <div>
                        <img src={Charity2} />
                    </div>

                    <div>
                        <img src={Charity3} />
                    </div>
                </div>

                <div className='charity-footer m-b80'>
                    <img src={CharityFooter} />
                </div>
            </div>
        </>
    )
}
