import React from 'react'
// import AboutUsImg from '../../image/about-us.svg'
import './index.less'

export default function Research() {

    return (
        <>
        <div className='research-bg'></div>
        <div className='research-wrapper'>
            <p className='main-title'>Science for Health</p>
            <p className='m-b40'>Samatvam’s faculties have extensive research experience beginning 1978. Dr. S Srikanta has made unique clinical and basic research 
                contributions in the field of diabetes endocrinology, serving in the highest centers of medicine and science in the world 
                (Duke University Medical Center, Joslin Diabetes Center, Harvard Medical School, USA), and India (All India Institute of Medical 
                Sciences, New Delhi). Realizing the contemporary India’s needs, currently Samatvam focuses on socially relevant and economically 
                feasible clinical research to improve the overall health care and well-being of people suffering from diabetes and other endocrine 
                metabolic disorders. Thus the major thrust areas are preventive diabetology and cardiology (including the importance of life style 
                changes and “Spiritual Medicine”), and cost effective diabetes health care delivery.
            </p>

            <p className='m-b40'>The development area primarily concerns translational research, transferring of knowledge and findings from clinical research to 
                clinical practice. This research is directed towards strengthening the individual patient’s possibilities of optimal diabetes 
                management through self-care and empowerment. In the area or scientific and social research, Samatvam has focused on diabetes care in 
                resource limited settings, patient education and empowerment, psychosocial support and Spiritual Medicine.
            </p>

            <p className='m-b80'>Some of the specific research areas include: Diabetes health education, community diabetes care programs, prevention of diabetic 
                nephropathy, prevention of diabetic limb loss, prevention of dyslipidemia and ischemic heart disease in diabetics and 
                non-diabetics, cost-effective socially relevant diabetes health care technologies, health benefits of vegetarianism, weight 
                management, behavior modification and effective counseling, autoimmunity and sub clinical thyroid disease; Stop Diabetes And 
                Healthy Heart: Preventive Diabetology and Cardiology programs
            </p>

            <p className='title'>Science – Discovery – Innovation – Health</p>
            <p className='sub-title'>Diabetes and Cardiovascular Outcome – Global Clinical Trials</p>
            <ul>
                <li>TECOS – Duke, USA; Oxford, UK</li>
                <li>Savor Timi: Harvard, USA</li>
                <li>Canvas: George Institute, Australia</li>
            </ul>

            <p className='sub-title'>Childhood and Adolescent Diabetes</p>
            <ul>
                <li>CDiC (Changing Diabetes in children), Denmark</li>
                <li>LFAC (Life for a Child with Diabetes), Australia</li>
            </ul>

            <p className='sub-title'>Point of Care [POC] Devices for diabetes biomarkers</p>
            <ul>
                <li>Center for Nanoscience and Pathshod, Indian Institute of Science, Bangalore</li>
                <li>Prof Navakanta Bhat and Dr Vinay Chouhan</li>
                <li>ISN-ANIO Diabetic Kidney Disease Research Project [PI: Dr Urmila Anand]</li>
            </ul>

            <p className='sub-title'>Protein Glycation in Diabetes</p>
            <ul>
                <li>Indian Institute of Science, CENSE, Bangalore</li>
                <li>Ms Madhumati S Vaishnav, PhD Student</li>
            </ul>

            <p className='title'>Diabetes and Depression</p>
            <p className='sub-title'>INTERPRET DD: International Study on the Prevalence of Depression in Diabetes</p>
            <ul className='m-b80'>
                <li>Association for the Improvement of Mental Health- Switzerland and NIMHANS, India</li>
                <li>18 countries; > 3000 patients</li>
                <li>India: NIMHANS – Samatvam Jnana Sanjeevini</li>
                <li>“Happy Minds Clinic” Every Friday [Liaison Psychiatry]</li>
                <li>2016: Investigators [30] Meeting, Bangalore</li>
                <li>“Spiritual Medicine”</li>
                <ul>
                    <li>Lord Sri Krishna and Bhagavad Gita</li>
                </ul>
            </ul>


        </div>
        </>
    )
}