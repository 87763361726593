import React from 'react'
import {
  Link
} from "react-router-dom";
import { Modal } from 'antd';
import './index.less';
import HomeCarousel1 from '../../image/home-caro-1.png'
import HomeCarousel2 from '../../image/home-caro-2.png'
import HomeCarousel3 from '../../image/home-caro-3.png'
import HomeCarousel4 from '../../image/home-caro-4.png'
import HomeCarousel5 from '../../image/home-caro-5.png'
import HomeCarousel6 from '../../image/home-caro-6.png'
import HomeCarousel7 from '../../image/home-caro-7.png'
import HomeCarousel8 from '../../image/home-caro-8.png'
import HomeCarousel9 from '../../image/home-caro-9.png'
import HomeCarousel10 from '../../image/home-caro-10.png'
import {Carousel} from '../../components/Base/Layout.js';

class Homepage extends React.Component {
// export default function Homepage() {
	state = { visible: false };

	showModal = () => {
		this.setState({visible: true})
	  };
	  handleOk = e => {
		console.log(e);
		this.setState({
		  visible: false,
		});
	  };
	
	  handleCancel = e => {
		console.log(e);
		this.setState({
		  visible: false,
		});
	  };
	  render () {
return (
  <>
	{/* Home Page Banner */}
	{/* <div className='home-banner'></div> */}
	{/* <div className='home-banner'>
		
	</div> */}
	<section className='full-home-wrapper'>
		<section className='home-wrapper'>
			<Carousel autoplay>
				<div>
					<img src={HomeCarousel1} />
					<div className='carousel-info'>
						<p className='name'>Service with Devotion</p>
						<p className='label'><b>Samatvam Endocrinology Diabetes</b> is a group of health care institutions that specialize in 
							treating and managing diabetes and other endocrine metabolic disorders.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel2} />
					<div className='carousel-info'>
						<p className='name'>Care and Compassion</p>
						<p className='label'><b>Jnana Sanjeevini</b> (= knowledge for health and life) is a dedicated 
							multispecialty and community health care project of Samatvam Trust, initiated in 2003, 
							in JP Nagar, Bangalore, India.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel3} />
					<div className='carousel-info'>
						<p className='name'>Excellence and Eminence</p>
						<p className='label'><b>Samatvam and Jnana Sanjeevini</b> are the home of one of the most 
							highly eminent and recognized Endocrinology Diabetes clinicians in India, with national 
							and international experience and pioneering accomplishments.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel4} />
					<div className='carousel-info'>
						<p className='name'>Insulin Pump and Continuous Glucose Monitoring Program</p>
						<p className='label'>Samatvam implemented in 2002, the first certified Insulin Pump and 
							Continuous Glucose Monitoring Program in India.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel5} />
					<div className='carousel-info'>
						<p className='name'>Artificial Pancreas – Automated Insulin Delivery Loop Project</p>
						<p className='label'>Samatvam implemented in 2020, the first ever Artificial Pancreas Program in India, 
							in collaboration with Stanford University, USA.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel6} />
					<div className='carousel-info'>
						<p className='name'>Science and Research</p>
						<p className='label'>In the area of scientific and social research, Samatvam has focused on:  
							Diabetes care in resource limited settings, Patient education and empowerment, 
							Psychosocial support and Spiritual Medicine.
						</p>
						
					</div>
				</div>
				<div>
					<img src={HomeCarousel7} />
					<div className='carousel-info'>
						<p className='name'>Samatvam Jnana Sanjeevini Celebrated 25 Years of public service - 2012</p>
						<p className='label'>Touched the health and lives of more than 100000 fellow citizens with 
							diabetes - adults and children, poor and rich, from Karnataka and neighboring states and 
							even other parts of India, and few from other countries from USA to Australia]
						</p>	
					</div>
				</div>
				<div>
					<img src={HomeCarousel8} />
					<div className='carousel-info'>
						<p className='name'>Igniting the Minds – Torch of Life</p>
						<p className='label'>Continuing Medical Education is directed towards Diabetes physicians, 
							counsellors and other health professionals.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel9} />
					<div className='carousel-info'>
						<p className='name'>Smiles – Life</p>
						<p className='label'>Each patient deserves the best of our knowledge, skills and most importantly 
							humanity.
						</p>
					</div>
				</div>
				<div>
					<img src={HomeCarousel10} />
					<div className='carousel-info'>
						<p className='name'>Science – Spirituality Synthesis</p>
						<p className='label'>In the context of health care: “Bridging the Best of East and West”, 
							is our goal; “Integration of Science and Spirituality” is our foundation.
						</p>
					</div>
				</div>
			</Carousel>
			<div className='home-wrapper-right'>
				<p className='action' onClick={this.showModal}>Membership</p>
				<p className='action' onClick={this.showModal}>Appointments</p>
				<p className='action' onClick={this.showModal}>Ask Your Doctor</p>
				<p className='action' onClick={this.showModal}>Teleconsultation</p>
				<p className='action' onClick={this.showModal}>Helplines</p>
			</div>
		</section>

	
		<section className='achivement-news-wrapper m-tb-80'>
			<div className='d-f'>
				<div className='achieve-news-wrapper d-f'>
					<div>
					<p className='name'>Samatvam</p>
					<p className='label'>Service with Devotion</p>
					<p className='p-t5 info'>
						<span className='imp'>Samatvam Endocrinology Diabetes</span> is a group of health care institutions that specialize in treating and
						managing diabetes and other endocrine metabolic disorders. The central goal is to provide health care of 
						highest possible quality to the highest possible quantity - to foster humane, honest and holistic health
						care. The central philosphy is the harmonious integration of science and spirituality in the context of 
						health care. Founded in 1987, <b>Samatvam</b> <i>(= equanimity)</i> has four core service areas: health care, ediucation, 
						research and social welfare. Currently, we serve through several health care - medical facilities.
					</p>
					<Link to='/about-us' className='know-more'>Know More...</Link>
					</div>
				</div>

				<div className='achieve-news-wrapper d-f m-l50'>
					<div>
						<p className='name'>Jnana Sanjeevani</p>
						<p className='label'>Care and Compassion</p>
						<p className='p-t5 info'> 
							<span className='imp'>Jnana Sanjeevani</span> <i>(=knowledge for health and life)</i> is a dedicated multispeciality and 
							community helath care project of <span className='imp'>Samatvam Trust</span>, initiated in 2003, in JP Nagar, Bangalore.
							Its aim is to provide multidiscplinary care for diabetes and related health problems "all under one proof", 
							irrespective of the socioeconomic status of the beneficiaries. Jnana Sanjeevani is a total health project, 
							which hopes to scientificially combine and integrate both the physical and spiritual dimensions of health, 
							towards human and society good.
						</p>
						<Link to='/about-us' className='know-more'>Know More...</Link>
					</div>
				</div>

				<div className='achieve-news-wrapper d-f m-l50'>
					<div>
						<p className='name'>Doctors - Team</p>
						<p className='label'>Excellence and Eminence</p>
						<p className='p-t5 info'> 
							<span className='imp'>Samatvam</span> and <span className='imp'>Jnana Sanjeevani</span> are the home of one of the most highly eminent and 
							recognized Endocrinology Diabetes Clinicians in India, with national and international 
							experience and pioneering accomplishments. "State of the Art" specialized and advanced medical 
							care , is always combined with highest scientific spirit and compassionate health care. Knowledge 
							and ever continuing learning, socially relevant clinical practise and focus on <span className='imp'>
							"Power of Prevention" </span> are the unique strengths. Multidiscplinary and team care ensure optimal health outcomes.
						</p>
						<Link to='/doctors' className='know-more'>Know More...</Link>
					</div>
				</div>
			</div>
		</section>
		
		{/* all url lists */}
		<section className='all-links m-b80'>
			<ul className='d-f'>
				<li className='achievemnets'><Link to="/achievements">Achievements</Link></li>
				<li><Link to="/news-events">News and Events</Link></li>
				<li><Link to="/newsletter">Newsletter</Link></li>
				<li><Link to="/photos-gallery">Photo Video Gallery</Link></li>
				<li><Link to="/testimonial">Testimonials</Link></li>
				<li><Link to="/international-patients">International Patients</Link></li>
				<li><Link to="/charity">Charity Donations</Link></li>
				<li className='health-professional'><Link to="/health-professional">Health Professionals</Link></li>
				<li className='staff-link' onClick={this.showModal}>Staff</li>
			</ul>
		</section>

		<Modal visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <p className='main-title'>Coming Soon...</p>
        </Modal>
	</section>

	</>  
	)}
}

export default Homepage;